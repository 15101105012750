import React from 'react';
import { S3_URL } from '../../../constants';
import { ChatMessageContainer, MessageBubbleContainer } from '../../primitives/containers';
import { GenericText } from '../../primitives/typography';

interface MessageProps {
    pfp?: String | null
    timestamp?: number | null
    content: String | null
    name: String | null
    email: String | null
    user_id: String | null
};

export const MyMessage = ({ ...props }: MessageProps) => {
    return <ChatMessageContainer style={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        alignItems: "flex-end"
    }}>
        <div>
            <div style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5vw",
                justifyContent: "flex-start",
                width: "100%"
            }}>
                <GenericText style={{ color: "gray", fontSize: "1.5vh" }}>You</GenericText>
                <GenericText></GenericText>
            </div>
            <MessageBubbleContainer style={{
                backgroundColor: "#6EA8C8",
                color: "white",
                marginTop: "0.5vh",
                borderBottomRightRadius: 0
            }}>
                {props.content}
            </MessageBubbleContainer>
        </div>
    </ChatMessageContainer>
};

export const OtherMessage = ({ ...props }: MessageProps) => {
    return <ChatMessageContainer style={{
        gap: "0.5vw"
    }}>
        <img style={{
            width: "3vw",
            height: "3vw",
            borderRadius: "300vh"
        }} src={S3_URL + props.pfp} />
        <div style={{
            display: "flex",
            flexDirection: "column",
            gap: "1vh"
        }}>
            <div style={{ width: "100%", display: "flex", alignItems: 'center', justifyContent: "space-between" }}>
                <GenericText style={{ color: "gray", fontSize: "1.5vh" }}>{props.name}</GenericText>
            </div>
            <MessageBubbleContainer style={{
                backgroundColor: "lightgray",
                color: "black",
                borderTopLeftRadius: 0
            }}>
                {props.content}
            </MessageBubbleContainer>
        </div>
    </ChatMessageContainer>
};