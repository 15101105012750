import React, { useEffect, useState } from 'react';

import io from 'socket.io-client';

import { FiBell } from 'react-icons/fi';

import { useFindChannelsQuery, useFindMessages2Mutation, useFindMessagesActivityQuery, useFindFeedbackActivityQuery } from '../../../state/api';

import { AppHeaderContainer, NotificationBadgeContainer, NotificationsButton, ProfileButton } from '../../primitives/containers';

// @ts-ignore
import DefaultPFP from '../../../assets/default_pfp.jpg';
import { HeaderNavButton } from '../../primitives/interactions';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { S3_URL, WS_API_URL } from '../../../constants';

interface AppHeaderProps {
    openNotificationDrawer?: Function
};

const AppHeader = ({ ...props }: AppHeaderProps) => {
    const [socket, setSocket] = useState(null);
    const [messages, setMessages] = useState([]);
    const [feedback, setFeedback] = useState([]);
    const [totalNotifs, setTotalNotifs] = useState(0);

    const navigate = useNavigate();

    const { channel_id } = useParams();

    // @ts-ignore
    const { data: channelData, isSuccess: isChannelSuccess } = useFindChannelsQuery();

    // @ts-ignore
    const { data: messagesData, isSuccess: messagesSuccess } = useFindMessagesActivityQuery();

    // @ts-ignore
    const { data: feedbackData, isSuccess: feedbackSuccess } = useFindFeedbackActivityQuery();

    // @ts-ignore
    useEffect(() => {
        const new_socket = io(WS_API_URL, {
            extraHeaders: {
                // @ts-ignore
                authorization: window.localStorage.getItem("_token"),
                "ngrok-skip-browser-warning": "any"
            }
        });

        // @ts-ignore
        setSocket(new_socket);
        return () => new_socket.close();
    }, []);

    if (isChannelSuccess) {
        const channels = channelData.data;

        channels.forEach(async (c: any) => {
            if (socket) {
                // @ts-ignore
                socket.on("message_sent:channel_id:" + c.channel_id, (data: any) => {
                    if (data.author.user_id !== window.localStorage.getItem("_user_id") && channel_id === undefined) {
                        let curr = [...messages];

                        // @ts-ignore
                        curr.push(data);
                        setMessages(curr);
                        setTotalNotifs(totalNotifs + 1);
                    }
                })
            }
        })
    }

    if (messagesSuccess) {
        const ms = messagesData.data;

        ms.forEach((m: any) => {
            // @ts-ignore
            if (!messages.includes(m) && m.author.user_id !== window.localStorage.getItem("_user_id")) {
                let curr = [...messages];
                // @ts-ignore
                curr.push(m);
                // @ts-ignore
                setMessages(curr);
                // @ts-ignore
                setTotalNotifs(totalNotifs + 1);
            }
        })
    }

    if (feedbackSuccess) {
        if (feedbackData.error === undefined) {
            const fs = feedbackData.data;
            fs.forEach((f: any) => {
                // @ts-ignore
                if (!feedback.includes(f)) {
                    let curr = [...feedback];
                    // @ts-ignore
                    curr.push(f);
                    // @ts-ignore
                    setFeedback(curr);
                    // @ts-ignore
                    setTotalNotifs(totalNotifs + 1);
                }
            })
        }
    }

    return (
        <AppHeaderContainer style={{
            zIndex: 2,
            paddingLeft: "4vw",
            paddingRight: "6vw"
        }}>
            <div style={{
                display: "flex",
                alignItems: "center",
                gap: "1vw"
            }}>
                <HeaderNavButton onClick={() => {
                    navigate(-1);
                }}>
                    <FaArrowLeft />
                </HeaderNavButton>
                <HeaderNavButton onClick={() => {
                    navigate(1);
                }}>
                    <FaArrowRight />
                </HeaderNavButton>
            </div>

            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <NotificationsButton onClick={() => {
                    // @ts-ignore
                    props.openNotificationDrawer(feedback, messages);
                }} style={{
                    cursor: "pointer",
                    position: "relative"
                }}>
                    <FiBell />
                    {(totalNotifs > 0) ? <NotificationBadgeContainer>
                        {(totalNotifs < 10) ? totalNotifs : totalNotifs + "+"}
                    </NotificationBadgeContainer> : <></>}
                </NotificationsButton>
                <ProfileButton onClick={() => {
                    navigate("/preferences");
                }}>
                    <img style={{ width: "2.5vw", height: "2.5vw", borderRadius: "100vh" }} src={S3_URL + window.localStorage.getItem("_pfp")} />
                </ProfileButton>
            </div>
        </AppHeaderContainer>
    );
};

export default AppHeader;