import styled from 'styled-components';

export const HeaderLogoText = styled.a`
    font-size: 2vh;
    font-family: Inter, sans-serif;
    font-weight: bold;
`;

export const LandingHeroHeader = styled.h1`
    font-family: Inter, sans-serif;
    font-size: 7vh;
    width: 1vw;
    margin-bottom: 0;
`;

export const LandingHeroDescription = styled.p`
    font-family: Inter, sans-serif;
    font-size: 2.5vh;
    color: gray;
    width: 25vw;
`;

export const FeaturesText = styled.a`
    font-size: 2.5vh;
    font-family: Inter, sans-serif;
    color: #6EA8C8;
`;

export const FeaturesHeader = styled.h1`
    font-size: 5vh;
    font-family: Inter, sans-serif;
`;

export const FeatureTitle = styled.h1`
    font-size: 3vh;
    font-family: Inter, sans-serif;
    margin-bottom: 0;
`;

export const FeatureDescription = styled.p`
    font-size: 2vh;
    font-family: Inter, sans-serif;
    color: gray;
    width: 17vw;
    text-align: center;
`;

export const FeaturesDescription = styled.p`
    font-family: Inter, sans-serif;
    font-size: 2.5vh;
    color: gray;
    width: 40vw;
    text-align: center;
`;

export const FeatureContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const FeaturesGroup = styled.div`
    display: flex;
    gap: 10vw;
    flex-wrap: wrap;
    margin-top: 10vh;
`;

export const InputLabel = styled.a`
    font-family: Inter, sans-serif;
    font-size: 2vh;
    color: gray;
`;

export const Link = styled.a`
    font-family: Inter, sans-serif;
    font-size: 2vh;
    text-decoration: none;
    color: #6EA8C8;
`;

export const RoleHeader = styled.a`
    font-family: Inter, sans-serif;
    font-size: 2vh;
`;

export const PlanHeader = styled.a`
    font-family: Inter, sans-serif;
    font-size: 2vh;
    font-weight: bold;
`;

export const UserNameText = styled.a`
    font-family: Inter, sans-serif;
    font-size: 1.5vh;
`;

export const PageHeaderTitle = styled.a`
    font-family: Inter, sans-serif;
    font-size: 3vh;
`;

export const PageHeaderDescription = styled.a`
    font-family: Inter, sans-serif;
    font-size: 2vh;
    color: gray;
`;

export const ClassTitleText = styled.a`
    font-family: Inter, sans-serif;
`;

export const ClassMemberCountText = styled.a`
    font-family: Inter, sans-serif;
    font-size: 3vh;
    color: rgb(0,0,0, 0.5);
`;

export const ActivityStudentText = styled.a`
    font-family: Inter, sans-serif;
    font-size: 2vh;
`;

export const ModalHeaderTitle = styled.a`
    font-family: Inter, sans-serif;
    font-size: 2vh;
`;

export const CodeTitle = styled.a`
    font-family: Inter, sans-serif;
    font-size: 2vh;
    display: flex;
    align-items: center;
    gap: 0.5vw;
    color: #344054;
`;

export const JoinCode = styled.a`
    font-family: Inter, sans-serif;
    font-size: 1.5vh;
    color: black;
    opacity: 0.5;
    padding-bottom: 1vh;
    padding-top: 1vh;
`;

export const GenericText = styled.a`
    font-family: Inter, sans-serif;
    font-size: 2vh;
`;