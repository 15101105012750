import React from 'react';
import styled from 'styled-components';

import { Oval } from 'react-loader-spinner';

interface InteractionProps {
    large?: boolean
    status?: boolean | string
    left?: boolean
    right?: boolean
    valid?: boolean
}

export const SignUpButton = styled.button<InteractionProps>`
    background-color: #6EA8C8;
    padding-left: 2vh;
    padding-right: 2vh;
    padding-top: 1vh;
    padding-bottom: 1vh;
    border-radius: 1vh;
    color: white;
    border: none;
    cursor: pointer;
    outline: none;
    font-size: ${(props) => (props.large) ? "3vh" : "2vh"};
    font-family: Inter, sans-serif;
`;

export const SignInButton = styled.button<InteractionProps>`
    background-color: white;
    border: 1px solid lightgray;
    font-family: Inter, sans-serif;
    padding-left: 2vh;
    padding-right: 2vh;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-size: 2vh;
    border-radius: 1vh;
    cursor: pointer;
`;

export const AuthInput = styled.input<InteractionProps>`
    font-family: Inter, sans-serif;
    font-size: 2vh;
    padding: 2vh; 
    border-radius: 1vh;
    border: ${(props) => (props.status) ? "2px solid lightgray" : "2px solid red"};
    outline: none;
    transition: all 0.3s ease 0.1s;
    box-sizing: border-box;
    width: 100%;

    &:focus {
        border: ${(props) => (props.status) ? "2px solid #6EA8C8" : "2px solid red"};
    }
`;

export const SubmitButtonWrapper = styled.button`
    background-color: #6EA8C8;
    padding-left: 2vh;
    padding-right: 2vh;
    padding-top: 2vh;
    padding-bottom: 2vh;
    border-radius: 1vh;
    color: white;
    border: none;
    cursor: pointer;
    outline: none;
    font-size: 2vh;
    font-family: Inter, sans-serif;
    width: 100%;
    margin-top: 4vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const SubmitButton = (props: any) => <SubmitButtonWrapper {...props} >
    {
        (props.loading) ? <Oval
            height={20}
            width={20}
            color="#6EA8C8"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="white"
            strokeWidth={2}
            strokeWidthSecondary={2}
        /> : props.children
    }
</SubmitButtonWrapper>;

export const RoleButton = styled.button<InteractionProps>`
    background-color: ${(props) => (props.status) ? "#6EA8C8" : "white"};
    border: ${(props) => (props.status) ? " 2px solid #6EA8C8;" : "2px solid lightgray;"};
    width: 12vw;
    height: 12vw;
    border-radius: 2vh;
    cursor: pointer;
    transition: all 0.3s ease 0.1s;

    &:hover {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
`;

export const AppNavButton = styled.button<InteractionProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    background-color: white;
    border: none;
    font-family: Inter, sans-serif;
    font-size: 2vh;
    padding: 1.5vh;
    background-color: ${(props) => (props.status) ? "#6EA8C8" : "white"};
    color: ${(props) => (props.status) ? "white" : "black"};
    border-radius: 2vh;
    gap: 1vw;
    transition: all 0.3s ease 0.1s;
    cursor: pointer;

    &:hover {
        background-color: ${(props) => (props.status) ? "#6EA8C8" : "whitesmoke"};
    }
`;

export const SignOutButton = styled.button`
    background-color: white;
    border: none;
    outline: none;
    color: gray;
    font-size: 3vh;
    transition: all 0.3s ease 0.1s;
    cursor: pointer;

    &:hover {
        color: red;
    }
`;

export const PageHeaderButton = styled.button<InteractionProps>`
    font-family: Inter, sans-serif;
    background-color: ${(props) => (props.status) ? "#ECF8FF" : "white"};
    border: none;
    font-size: 2vh;
    padding-left: 1vw;
    padding-right: 1vw;
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    border-radius: 1.5vh;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5vw;
`;

export const GroupButton = styled.button<InteractionProps>`
    font-family: Inter, sans-serif;
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 1.5vh;
    padding: 2vh;
`;

export const SearchInput = styled.input`
    padding: 1.5vh;
    height: min-content;
    font-size: 1.5vh;
    width: 30vw;
    border-radius: 1vh;
    border: 2px solid lightgray;
    transition: all 0.3s ease 0.1s;
    outline: none;

    &:focus {
        border: 2px solid #6EA8C8;
    }
`;

export const ExitButton = styled.button`
    background-color: transparent;
    border: none;
    font-size: 2vh;
    transition: all 0.3s ease 0.1s;
    outline: none;
    color: lightgray;
    cursor: pointer;

    &:hover {
        color: black;
    }
`;

export const GenericInput = styled.input<InteractionProps>`
    padding: 1.5vh;
    height: min-content;
    font-size: 1.5vh;
    width: 100%;
    box-sizing: border-box;
    border-radius: 1vh;
    border: ${(props) => (props.status) ? "2px solid lightgray" : "2px solid red"};
    transition: all 0.3s ease 0.1s;
    outline: none;

    &:focus {
        border: ${(props) => (props.status) ? "2px solid #6EA8C8" : "2px solid red"};
    }
`;

export const GenericTextArea = styled.textarea<InteractionProps>`
    resize: none;
    font-family: Inter, sans-serif;
    padding: 1.5vh;
    height: min-content;
    font-size: 1.5vh;
    width: 100%;
    box-sizing: border-box;
    border-radius: 1vh;
    border: ${(props) => (props.status) ? "2px solid lightgray" : "2px solid red"};
    transition: all 0.3s ease 0.1s;
    outline: none;

    &:focus {
        border: ${(props) => (props.status) ? "2px solid #6EA8C8" : "2px solid red"};
    }
`;

export const RemoveClassButton = styled.button`
    font-family: Inter, sans-serif;
    background-color: #ff9999;
    border: none;
    font-size: 2vh;
    padding-left: 1vw;
    padding-right: 1vw;
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    border-radius: 1.5vh;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5vw;
    color: #b20000;
    transition: all 0.3s ease 0.1s;

    &:hover {
        background-color: red;    
        color: white;
    }
`;

export const DisplayCodeButton = styled.button`
    font-size: 1.5vh;
    padding: none;
    background-color: transparent;
    border: none;
    outline: none;
    color: black;
    opacity: 0.5;
`;

export const MemberOptionButton = styled.button<InteractionProps>`
    font-size: 2.5vh;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: lightgray;
    transition: all 0.3s ease 0.1s;

    &:hover {
        color: ${(props) => (props.status) ? "red" : "#6EA8C8"};
        font-size: 2.6vh;
    }
`;

export const FeedbackInput = styled.textarea`
    resize: none;
    height: 20vh;
    box-sizing: border-box;
    font-family: Inter, sans-serif;
    font-size: 2vh;
    border: none;
    outline: none;
    padding: none;
    background-color: #ECF8FF;
`;

export const ProgressButton = styled.button<InteractionProps>`
    flex: 1;
    padding: 1vh;
    font-family: Inter, sans-serif;
    border-radius: 1vh;
    font-size: 2vh;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    flex-align: center;
    gap: 0.5vw;
    background-color: ${(props) => (props.status === "behind" && props.valid) ? "#F9C11D" : (props.status === "on_track" && props.valid) ? "#6EA8C8" : (props.status === "exceeding_expectations" && props.valid) ? "#0ad48b" : "transparent"};
    color: ${(props) => (props.valid) ? "white" : "gray"};
    justify-content: center;
`;

export const HeaderNavButton = styled.button`
    width: 2vw;
    height: 2vw;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 2vh;
    cursor: pointer;
    transition: all 0.3s ease 0.1s;
    color: gray;

    &:hover {
        color: #6EA8C8;
    }
`;

export const ProfileButton = styled.button`

`;

export const SettingButton = styled.button<InteractionProps>`
    background-color: ${(props) => (!props.status) ? "white" : "whitesmoke"};
    border: none;
    outline: none;
    padding: 4vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 2vh;
    width: 100%;
    transition: all 0.3s ease 0.1s;
    
    &:hover {
        background-color: whitesmoke;
        cursor: pointer;
    }
`;

export const SettingSubmitButton = styled.button<InteractionProps>`
    background-color: ${(props) => (props.status) ? "#6EA8C8" : "#99c2d8"};
    padding-left: 2vh;
    padding-right: 2vh;
    padding-top: 2vh;
    padding-bottom: 2vh;
    border-radius: 1vh;
    color: white;
    border: none;
    cursor: ${(props) => (props.status) ? "pointer" : "cursor"};
    outline: none;
    font-size: 2vh;
    font-family: Inter, sans-serif;
    width: 100%;
    margin-top: 4vh;
`;

export const ChatTextArea = styled.input`
    flex: 1;
    resize: none;
    border: none;
    font-family: Inter, sans-serif;
    outline: none;
    font-size: 2vh;
    padding: 3vh;
`;

export const NotiButton = styled.button`
    width: 100%;
    box-sizing: border-box;
    padding: 1.5vh;
    display: flex;
    justify-content: flex-start;
    border-radius: 2vh;
    border: none;
    align-items: flex-start;
    gap: 0.5vw;
    cursor: pointer;
`;