import React, { useState } from 'react';

import { HeaderIcon } from '../../components/primitives/images';
import { HeaderLogoText, InputLabel, LandingHeroDescription, LandingHeroHeader, Link } from '../../components/primitives/typography';
import { HeaderIconContainer, MainContainer, SignInFormContainer } from '../../components/primitives/containers';
import { AuthInput, SubmitButton } from '../../components/primitives/interactions';

import {
    useSignInMutation,
    useFindMeMutation
} from '../../state/api';

// @ts-ignore
import Logo from '../../assets/logo.svg';

import { Oval } from 'react-loader-spinner';

const SignIn = () => {
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [emailStatus, setEmailStatus] = useState(true);
    const [passwordStatus, setPasswordStatus] = useState(true);

    const [signIn, {
        isLoading: isSignInLoading,
        isSuccess: isSignInSuccess
    }] = useSignInMutation();
    const [findMe] = useFindMeMutation();

    const submitUser = async () => {
        const signin_request = {
            email,
            password
        };

        // @ts-ignore
        const signin_response = await signIn(signin_request);

        // @ts-ignore
        if (signin_response.data.authorization) {
            // @ts-ignore
            const authorization = signin_response.data.authorization;

            const user = await findMe({ authorization });

            // @ts-ignore
            window.localStorage.setItem("_pfp", user.data.data.pfp);
            // @ts-ignore
            window.localStorage.setItem("_user_id", user.data.data.user_id);
            // @ts-ignore
            window.localStorage.setItem("_role", user.data.data.role);
            // @ts-ignore
            window.localStorage.setItem("_email", user.data.data.email);
            // @ts-ignore
            window.localStorage.setItem("_name", user.data.data.name);
            // @ts-ignore
            window.localStorage.setItem("_token", authorization);
            window.location.reload();
            window.location.href = "/";
        } else {
            setEmailStatus(false);
            setPasswordStatus(false);
        }
    }

    return (
        <MainContainer style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <SignInFormContainer>
                <HeaderIconContainer>
                    <HeaderIcon src={Logo} />
                    <HeaderLogoText>upstream</HeaderLogoText>
                </HeaderIconContainer>

                <LandingHeroHeader style={{
                    width: "30vw",
                    fontSize: "5vh"
                }}>Sign In</LandingHeroHeader>

                <LandingHeroDescription>Welcome back! Please enter your details.</LandingHeroDescription>

                <div style={{ display: "flex", flexDirection: "column", gap: "3vh" }}>
                    <div style={{ display: "flex", flexDirection: "column", gap: "1vh" }}>
                        <InputLabel>Email</InputLabel>
                        <AuthInput status={emailStatus} type={"email"} placeholder={"Enter your email"} onChange={(e) => {
                            // @ts-ignore
                            setEmail(e.target.value);
                        }} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", gap: "1vh" }}>
                        <InputLabel>Password</InputLabel>
                        <AuthInput status={passwordStatus} type={"password"} placeholder={"Enter your password"} onChange={(e) => {
                            // @ts-ignore
                            setPassword(e.target.value);
                        }} />
                    </div>
                </div>

                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "3vh"
                }}>
                    <Link href={"/forgot-password"}>Forgot password?</Link>
                    <Link href={"/registration"}>Register.</Link>
                </div>

                <SubmitButton loading={isSignInLoading} onClick={() => { submitUser(); }} style={{ width: "100%" }}>Sign In</SubmitButton>
            </SignInFormContainer>
        </MainContainer>
    )
};

export default SignIn;