import React from 'react';

import { FaTimes } from 'react-icons/fa';

import { ModalHeaderTitle } from '../../../primitives/typography';
import { SignOutContainer, ModalHeaderContainer } from '../../../primitives/containers';
import { ExitButton, SubmitButton } from '../../../primitives/interactions';

import {
    useRemoveClassMutation
} from '../../../../state/api';
import { useParams } from 'react-router-dom';

interface ConfirmationProps {
    closeModal: Function
    title: String
    signOut: Function
    class_id?: String
    removeGoal: Function
    removeMember: Function
    removeFeedback: Function
}

const SignOut = ({ ...props }: ConfirmationProps) => {
    const [removeThisClass, { data: removeData, isLoading: isRemoveLoading, isSuccess: isRemoveSuccess }] = useRemoveClassMutation();

    const { class_id } = useParams();

    const RemoveClass = async () => {
        // @ts-ignore
        const results = await removeThisClass({ class_id });

        // @ts-ignore
        if (results.data.data.error) {
            props.closeModal();
        } else {
            window.location.href = "/";
            props.closeModal();
        }
    };

    return (
        <SignOutContainer style={{
            width: "30vw"
        }}>
            <ModalHeaderContainer>
                <ModalHeaderTitle style={{ fontSize: "2.5vh" }}>{props.title}</ModalHeaderTitle>
                <ExitButton onClick={() => {
                    props.closeModal();
                }}>
                    <FaTimes />
                </ExitButton>
            </ModalHeaderContainer>
            <SubmitButton onClick={() => {
                props.closeModal();
            }} style={{
                width: "100%",
                backgroundColor: "transparent",
                color: "#6EA8C8",
                padding: "none"
            }}>
                No, take me back.
            </SubmitButton>

            <SubmitButton loading={isRemoveLoading} onClick={() => {
                // @ts-ignore
                (props.title === "Remove Class") ? RemoveClass() : (props.title === "Remove Goal") ? props.removeGoal() : (props.title === "Remove Member") ? props.removeMember() : (props.title === "Remove Feedback") ? props.removeFeedback() : props.signOut();
            }} style={{
                width: "100%"
            }}>
                Yes, I'm sure.
            </SubmitButton>
        </SignOutContainer >
    );
};

export default SignOut;