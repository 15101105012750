import React from 'react';
import { MainContainer, SectionContainer, SignUpFormContainer } from '../components/primitives/containers';
import { HeaderIcon } from '../components/primitives/images';

// @ts-ignore
import Logo from '../assets/logo.svg';
import { FeatureDescription, FeatureTitle } from '../components/primitives/typography';
import { SubmitButton } from '../components/primitives/interactions';
import { useNavigate } from 'react-router-dom';

const FourOhFourPage = () => {
    const navigate = useNavigate();

    return (
        <MainContainer>
            <SectionContainer style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <SignUpFormContainer>
                    <HeaderIcon style={{
                        width: "3vw"
                    }} src={Logo} />
                    <FeatureTitle style={{
                        fontSize: "5vh"
                    }}>
                        Looks like you're lost.
                    </FeatureTitle>
                    <FeatureDescription style={{
                        fontSize: "2vh"
                    }}>

                    </FeatureDescription>
                    <SubmitButton onClick={() => {
                        navigate("/");
                    }}>Back to Home</SubmitButton>
                </SignUpFormContainer>
            </SectionContainer>
        </MainContainer>
    );
};

export default FourOhFourPage;