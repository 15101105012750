import React, { useState, useEffect } from 'react';

import Modal from '../../components/composite/modal';

import AppHeader from '../../components/composite/headers/appheader';
import ChatHeader from '../../components/composite/headers/chatheader';
import ChatWindow from '../../components/composite/chat/window';
import AppNavigation from '../../components/nav/app';

import FourOhFourPage from '../404';

import Drawer from '../../components/composite/drawer';

import { ContentContainer, ContentInterior, MainContainer } from '../../components/primitives/containers';
import { useFindChannelsQuery } from '../../state/api';
import { useParams } from 'react-router-dom';

import { Oval } from 'react-loader-spinner';

const Channel = () => {
    const [drawerType, setDrawerType] = useState("");
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerData, setDrawerData] = useState(null);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalType, setModalType] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [socket, setSocket] = useState(null);

    const { channel_id } = useParams();

    // @ts-ignore
    const { data, isSuccess, isLoading } = useFindChannelsQuery();

    const signOut = () => {
        // @ts-ignore
        setModalTitle("Sign Out");
        // @ts-ignore
        setModalType("confirmation");
        setModalOpen(true);
    };

    const SignOut = () => {
        window.localStorage.removeItem("_token");
        window.localStorage.removeItem("_name");
        window.localStorage.removeItem("_email");
        window.localStorage.removeItem("_role");

        window.location.reload();
        window.location.href = "/";
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const openNotificationDrawer = (unread_feedback: Array<Object>, unread_messages: Array<Object>) => {
        // @ts-ignore
        setDrawerData({ unread_feedback, unread_messages });
        setDrawerType("notifications");
        setDrawerOpen(true);
    }

    const closeNotificationDrawer = () => {
        setDrawerData(null);
        setDrawerType("");
        setDrawerOpen(false);
    }

    let this_channel = [];
    let other_member = [];

    if (isSuccess) {
        this_channel = data.data.filter((c: any) => {
            return c.channel_id === channel_id;
        });

        this_channel = this_channel[0];
        other_member = this_channel.members.filter((m: any) => {
            return m.user_id !== window.localStorage.getItem("_user_id");
        });
        other_member = other_member[0];
    }

    return (
        <MainContainer style={{
            display: "flex",
            overflowY: "hidden"
        }}>
            <AppNavigation signOut={signOut} page={"messages"} />
            <ContentContainer style={{
                overflowY: "hidden"
            }}>
                <AppHeader openNotificationDrawer={openNotificationDrawer} />
                <ContentInterior style={{
                    height: "100%",
                    backgroundColor: "white",
                    padding: 0,
                    paddingTop: "9vh",
                    gap: 0
                }}>
                    {
                        (isSuccess) ? <>
                            <ChatHeader
                                pfp={other_member.pfp}
                                name={other_member.name}
                                email={other_member.email}
                                class_id={this_channel.classes[0]}
                                // @ts-ignore
                                user_id={(window.localStorage.getItem("_role") === "instructor") ? other_member.user_id : window.localStorage.getItem("_user_id")}
                            />
                            <ChatWindow
                                channel_id={this_channel.channel_id}
                                other_member={other_member}
                            />
                        </> : (isLoading) ? <div style={{
                            width: "100%",
                            height: "100vh",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <Oval
                                height={40}
                                width={40}
                                color="#6EA8C8"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="white"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                            />
                        </div> : <></>
                    }
                </ContentInterior>
                <Modal
                    open={modalOpen}
                    type={modalType}
                    closeModal={closeModal}
                    // @ts-ignore
                    title={modalTitle}
                    signOut={SignOut}
                />
                <Drawer
                    type={drawerType}
                    open={drawerOpen}
                    close={closeNotificationDrawer}
                    data={drawerData}
                />
            </ContentContainer>
        </MainContainer>
    );
};

export default Channel;