import React, { useState } from 'react';

import io from 'socket.io-client';

import { FaArrowRight } from 'react-icons/fa';
import { useParams } from 'react-router-dom';

import { useSendMessageMutation } from '../../../state/api';

import { ChatInputContainer } from '../../primitives/containers';
import { ChatTextArea, SubmitButton } from '../../primitives/interactions';

interface MessageInputProps {
    socket: any
}

const MessageInput = ({ ...props }: MessageInputProps) => {
    const { socket } = props;
    
    const [message, setMessage] = useState("");

    const [sendMessage] = useSendMessageMutation();

    const { channel_id } = useParams();

    const onSubmit = async (e: any) => {
        e.preventDefault();

        if(message !== null && message !== undefined) {
            console.log(socket.emit("new_message", {
                channel_id,
                content: message
            }));

            // @ts-ignore
            setMessage("");
        }
    };

    return (
        <form onSubmit={onSubmit}>
            <ChatInputContainer>
                {/* @ts-ignore */}
                <ChatTextArea value={message} onChange={(e) => {
                    // @ts-ignore
                    setMessage(e.target.value);
                }} placeholder={"Send a message..."}/>
                <SubmitButton type={"submit"} style={{
                    width: "max-content",
                    height: "100%",
                    display: "none"
                }}>
                    <FaArrowRight />
                </SubmitButton>
            </ChatInputContainer>
        </form>
    )
};

export default MessageInput;