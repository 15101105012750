import React, { useState } from 'react';
import { SectionContainer, SignUpFormContainer } from '../../../../components/primitives/containers';
import { HeaderIcon } from '../../../../components/primitives/images';

// @ts-ignore
import Logo from '../../../../assets/logo.svg';
import { FeatureDescription, FeatureTitle } from '../../../../components/primitives/typography';
import { AuthInput, SubmitButton } from '../../../../components/primitives/interactions';

interface EmailProps {
    incrementIndex: Function
    decrementIndex: Function
    setFinalEmail: Function
}

const Email = ({ ...props }: EmailProps) => {
    const {
        incrementIndex,
        decrementIndex,
        setFinalEmail
    } = props;

    const [email, setEmail] = useState(null);
    const [email2, setEmail2] = useState(null);
    const [emailStatus, setEmailStatus] = useState(true);
    const [email2Status, setEmail2Status] = useState(true);

    const SubmitEmail = (e: any) => {
        if (email === email2) {
            setEmailStatus(true);
            setFinalEmail(email);
            incrementIndex();
        } else {
            setEmail2Status(false);
        }
    }

    return (
        <SectionContainer style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <SignUpFormContainer>
                <HeaderIcon style={{
                    width: "3vw"
                }} src={Logo} />
                <FeatureTitle style={{
                    fontSize: "5vh"
                }}>Pick an email</FeatureTitle>
                <FeatureDescription style={{
                    fontSize: "2vh"
                }}>
                    Use your school email.
                </FeatureDescription>
                {/* @ts-ignore */}
                <AuthInput status={emailStatus} onChange={(e) => { setEmail(e.target.value) }} style={{ marginTop: "4vh", width: "25vw" }} placeholder={"Enter your email..."} />
                {/* @ts-ignore */}
                <AuthInput status={email2Status} onChange={(e) => { setEmail2(e.target.value) }} style={{ marginTop: "2vh" }} placeholder={"Re-enter your email..."} />
                <SubmitButton onClick={SubmitEmail}>Continue</SubmitButton>
            </SignUpFormContainer>
        </SectionContainer>
    );
};

export default Email;