import React from 'react';
import { useNavigate } from 'react-router-dom';

import { AiFillHome } from 'react-icons/ai';
import { BsStack } from 'react-icons/bs';
import { FiLogOut } from 'react-icons/fi';

// Composites
import Badge from '../composite/badge';

// Primitives
import { AppNavContainer } from '../primitives/containers';
import { AppNavButton, SignOutButton } from '../primitives/interactions';
import { HeaderIcon } from '../primitives/images';
import { HeaderLogoText, UserNameText } from '../primitives/typography';

// @ts-ignore
import Logo from '../../assets/logo.svg';

// @ts-ignore
import DefaultPFP from '../../assets/default_pfp.jpg';
import { IoMdSchool } from 'react-icons/io';
import { FaUserAlt } from 'react-icons/fa';
import { useGetImageQuery } from '../../state/api';
import { S3_URL } from '../../constants';


interface AppNavProps {
    page: String
    signOut: Function
}

const AppNavigation = ({ ...props }: AppNavProps) => {
    const {
        page
    } = props;

    const navigate = useNavigate();

    return (
        <AppNavContainer>
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "2vh",
                boxSizing: "border-box"
            }}>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1vw"
                }}>
                    <HeaderIcon style={{ width: "2.5vw" }} src={Logo} />
                    <HeaderLogoText>upstream</HeaderLogoText>
                </div>

                <Badge content={"beta"} />
            </div>
            <div style={{
                width: "100%",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                flex: 1,
                justifyContent: "space-between"
            }}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2vh",
                    padding: "2vh"
                }}>
                    <AppNavButton status={page === "home"} onClick={() => {
                        navigate("/");
                    }}>
                        <div style={{ display: "flex", alignItems: "center", gap: "1vw" }}>
                            <AiFillHome />
                            Home
                        </div>
                    </AppNavButton>
                    <AppNavButton status={page === "messages"} onClick={() => {
                        navigate("/messages");
                    }}>
                        <div style={{ display: "flex", alignItems: "center", gap: "1vw" }}>
                            <BsStack />
                            Messages
                        </div>
                    </AppNavButton>
                </div>

                <div style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    borderTop: "1px solid lightgray",
                    padding: "2vh",
                    boxSizing: "border-box",
                    justifyContent: "space-between"
                }}>
                    <div style={{
                        display: "flex",
                        alignItems: "center"
                    }}>
                        <img style={{
                            borderRadius: "100vh",
                            width: "3vw",
                            height: "3vw"
                        }} src={S3_URL + window.localStorage.getItem("_pfp")} />
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "1vw",
                            gap: "1vh"
                        }}>
                            <UserNameText style={{ fontWeight: "bold", display: "flex", alignItems: "center", gap: "0.5vw" }}>
                                {(window.localStorage.getItem("_role") === "instructor") ? <IoMdSchool color={"#6EA8C8"} /> : <FaUserAlt color={"#6EA8C8"} />}
                                {window.localStorage.getItem("_name")}
                            </UserNameText>
                            <UserNameText>{window.localStorage.getItem("_email")}</UserNameText>
                        </div>
                    </div>

                    <SignOutButton onClick={() => {
                        props.signOut();
                    }}>
                        <FiLogOut />
                    </SignOutButton>
                </div>
            </div>
        </AppNavContainer>
    )
};

export default AppNavigation;