import React, { useState, useEffect } from 'react';
import { BiMessageDetail } from 'react-icons/bi';

import { useNavigate } from 'react-router-dom';

import { FaTimes } from 'react-icons/fa';
import { MdFeedback } from 'react-icons/md';

// @ts-ignore
import GoalIcon from '../../../../assets/directions.svg';

import { ExitButton, NotiButton } from '../../../primitives/interactions';
import { GenericText } from '../../../primitives/typography';

import { useFindMessagesActivityQuery, useFindFeedbackActivityQuery } from '../../../../state/api';
import { io } from 'socket.io-client';
import { IoMdMap } from 'react-icons/io';
import { S3_URL, WS_API_URL } from '../../../../constants';

interface NotificationsViewProps {
    closeDrawer: Function
    open: Boolean
    data: any
};

interface NotificationButtonProps {
    pfp?: String
    name?: String
    content?: String
    goal_color?: String,
    type: String,
    channel_id?: String
    goal_id?: String
    goal_title?: String
    class_id?: String
    timestamp?: number
};

const NotificationButton = ({ ...props }: NotificationButtonProps) => {
    const navigate = useNavigate();

    const viewNoti = () => {
        if(props.type === "message") {
            navigate("/messages/channel/" + props.channel_id);
        } else {
            navigate("/class/" + props.class_id + "/goal/" + props.goal_id);
        }
    };

    const date = new Date(0);
    // @ts-ignore
    date.setUTCSeconds(props.timestamp / 1000);

    const dayOfWeek = (date.getDay() === 0) ? "Sunday" : "";

    const time = () => {
        let hours;
        let pm = false;
        if (date.getHours() > 12) {
            hours = date.getHours() - 12;
            pm = true;
        } else {
            hours = date.getHours();
        }

        return hours + ":" + ((date.getMinutes() < 10) ? "0" + date.getMinutes() : date.getMinutes()) + ((pm) ? "pm" : "am");
    }

    if(props.type === "message") {
        return (
            <NotiButton onClick={() => {
                viewNoti();
            }}>
                <img style={{
                    width: "3vw",
                    height: "3vw",
                    borderRadius: "100vh"
                }} src={S3_URL + props.pfp} />
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "0.5vh"
                }}>
                    <GenericText style={{
                        fontSize: "1.5vh",
                        fontWeight: "bold"
                    }}>{props.name}</GenericText>
                    <GenericText style={{
                        fontSize: "1.5vh",
                        color: "gray"
                    }}>{props.content}</GenericText>
                </div>
            </NotiButton>
        )
    } else {
        return <NotiButton onClick={() => {
            viewNoti();
        }}>
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "3vw",
                height: "3vw",
                // @ts-ignore
                backgroundColor: props.goal_color,
                color: "rgb(0, 0, 0, 0.5)",
                borderRadius: "2vh"
            }}>
                <img src={GoalIcon} style={{width: "2vw"}} />
            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start"
            }}>
                <GenericText style={{
                    fontSize: "1.5vh",
                    fontWeight: "bold"
                }}>{props.goal_title}</GenericText>
                <GenericText style={{
                    fontSize: "1.5vh",
                    color: "gray"
                }}>
                    {props.content}
                </GenericText>
            </div>
        </NotiButton>
    }
};

const NotificationsView = ({ ...props }: NotificationsViewProps) => {
    const [socket, setSocket] = useState(null);

    const viewNotification = () => {

    };

    const [messages, setMessages] = useState([]);

    // @ts-ignore
    const { data: messagesData, isSuccess: messagesSuccess } = useFindMessagesActivityQuery();

    // @ts-ignore
    const { data: feedbackData, isSuccess: feedbackSuccess } = useFindFeedbackActivityQuery();

    // @ts-ignore
    useEffect(() => {
        const new_socket = io(WS_API_URL, {
            extraHeaders: {
                // @ts-ignore
                authorization: window.localStorage.getItem("_token")
            }
        });

        // @ts-ignore
        setSocket(new_socket);
        return () => new_socket.close();
    }, []);

    if(messagesSuccess) {
        const ms = messagesData.data;

        ms.forEach((m: any) => {
            // @ts-ignore
            if(!messages.includes(m)) {
                // @ts-ignore
                let curr = [...messages];
                // @ts-ignore
                curr.push(m);
                // @ts-ignore
                setMessages(curr);
            }
        });

        const MessageComponents = props.data.unread_messages.map((m: any) => {
            if(socket) {
                // @ts-ignore
                socket.emit("message_received", {
                    channel_id: m.channel_id,
                    message_id: m.message_id
                });
            }

            return <NotificationButton 
                type={"message"}
                pfp={m.author.pfp}
                name={m.author.name}
                content={m.content}
                channel_id={m.channel_id}
            />
        });

        if(window.localStorage.getItem("_role") !== "instructor") {
            if(feedbackSuccess) {
                const FeedbackComponents = props.data.unread_feedback.map((f: any) => {
                    // if(socket) {
                    //     // @ts-ignore
                    //     socket.emit("view_feedback", {
                    //         goal_id: f.goal.goal_id
                    //     });
                    // }

                    return <NotificationButton 
                        type={"feedback"}
                        goal_color={f.goal.color}
                        goal_title={f.goal.title}
                        content={f.content}
                        class_id={f.goal.class_id}
                        goal_id={f.goal.goal_id}
                        timestamp={f.timestamp}
                    />
                });

                const totalComponents = MessageComponents.concat(FeedbackComponents);

                return (
                    <div style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        padding: "2vh",
                        gap: "2vh",
                        boxSizing: "border-box"
                    }}>
                        <div style={{
                            width: "100%",
                            boxSizing: "border-box",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between"
                        }}>
                            <ExitButton onClick={() => {
                                props.closeDrawer();
                            }}>
                                <FaTimes />
                            </ExitButton>
                        </div>
                        {(props.data.unread_messages.length === 0 && props.data.unread_feedback.length === 0) ? <div style={{
                            width: "100%",
                            borderRadius: "2vh",
                            backgroundColor: "whitesmoke",
                            flex: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <GenericText style={{ color: "gray", fontSize: "1.5vh" }}>No Notifications.</GenericText>
                        </div> : <></>}

                        {(props.data.unread_messages.length > 0) ? <div style={{
                            width: "100%",
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            overflowY: "scroll",
                            overflowX: "hidden",
                            gap: "2vh"
                        }}>
                            {totalComponents}
                        </div> : (props.data.unread_feedback.length > 0) ? <div style={{
                            width: "100%",
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            overflowY: "scroll",
                            overflowX: "hidden",
                            gap: "2vh"
                        }}>
                            {FeedbackComponents}
                        </div> : <></>}
                    </div>
                )
            }
        } else {
            return (
                <div style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    padding: "2vh",
                    gap: "2vh",
                    boxSizing: "border-box"
                }}>
                    <div style={{
                        width: "100%",
                        boxSizing: "border-box",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between"
                    }}>
                        <ExitButton onClick={() => {
                            props.closeDrawer();
                        }}>
                            <FaTimes />
                        </ExitButton>
                    </div>

                    {(props.data.unread_messages.length === 0) ? <div style={{
                        width: "100%",
                        borderRadius: "2vh",
                        backgroundColor: "whitesmoke",
                        flex: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <GenericText style={{
                            fontSize: "1.5vh",
                            color: "gray"
                        }}>No Recent Messages</GenericText>
                    </div> : <div style={{
                        width: "100%",
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        overflowY: "scroll",
                        overflowX: "hidden",
                        gap: "2vh"
                    }}>
                        {MessageComponents}
                    </div>}
                </div>
            )
        }
    }
};

export default NotificationsView;