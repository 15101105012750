import React, { useEffect, useState } from 'react';

import { CirclePicker } from 'react-color';
import { FaTimes } from 'react-icons/fa';
import { BiHomeAlt } from 'react-icons/bi';
import { IoMdMap } from 'react-icons/io';

// @ts-ignore
import GoalIcon from '../../../../assets/directions.svg';

import { ExitButton, GenericInput, GenericTextArea, SubmitButton } from '../../../primitives/interactions';
import { AddClassContainer, ModalHeaderContainer, ModalInteriorContainer, ClassIconBadgeContainerLarge } from '../../../primitives/containers';
import { ModalHeaderTitle } from '../../../primitives/typography';
import { useCreateClassMutation, useCreateGoalMutation } from '../../../../state/api';

interface AddGoalProps {
    closeModal: Function
    student_id: String
    class_id: String
};

const AddGoal = ({ ...props }: AddGoalProps) => {
    const [color, setColor] = useState(null);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [titleStatus, setTitleStatus] = useState(true);
    const [descriptionStatus, setDescriptionStatus] = useState(true);

    const colors = ["#e5c3c6", "#e1e9b7", "#f96161", "#bcd2d0", "#d0b783", "#363b74", "#673888"]

    const chooseColor = () => {
        return colors[Math.floor(Math.random() * colors.length)];
    }

    useEffect(() => {
        const color = chooseColor();
        // @ts-ignore
        setColor(color);
    }, []);

    const [createGoal, { data, isLoading, isSuccess }] = useCreateGoalMutation();

    const submitRequest = async () => {
        let regex = /^[a-zA-Z0-9 ]{3,20}$/

        if (regex.test(title) && title !== "" && description !== "") {
            const results = await createGoal({
                class_id: props.class_id,
                student_id: props.student_id,
                title,
                description,
                color
            });

            // @ts-ignore
            if (results.data.data.error) {
                setTitleStatus(false);
                setDescriptionStatus(false);
            } else {
                window.location.reload();
                props.closeModal();
            }
        } else {
            if (title === "" || !regex.test(title)) {
                setTitleStatus(false);
            }

            if (description === "") {
                setDescriptionStatus(false);
            }
        }
    }

    return (
        <AddClassContainer>
            <ModalHeaderContainer>
                <ModalHeaderTitle style={{ fontSize: "2.5vh" }}>Add Goal</ModalHeaderTitle>

                <ExitButton onClick={() => {
                    props.closeModal();
                }}>
                    <FaTimes />
                </ExitButton>
            </ModalHeaderContainer>

            <ModalInteriorContainer style={{
                gap: "2vh"
            }}>
                <div style={{
                    marginTop: "5vh",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    gap: "4vh",
                    marginBottom: "5vh"
                }}>
                    {/* @ts-ignore */}
                    <ClassIconBadgeContainerLarge color={color}>
                        <img src={GoalIcon} style={{
                            width: "3vw",
                            height: "3vw"
                        }} />
                    </ClassIconBadgeContainerLarge>
                    <CirclePicker onChange={(color, e) => {
                        // @ts-ignore
                        setColor(color.hex);
                    }} />
                </div>

                {/* @ts-ignore */}
                <GenericInput onChange={(e) => { setTitle(e.target.value) }} status={titleStatus} placeholder={"Enter a title for this goal..."} style={{ fontSize: "2vh" }} />
                {/* @ts-ignore */}
                <GenericTextArea onChange={(e) => { setDescription(e.target.value) }} status={descriptionStatus} placeholder={"Enter a description for this goal..."} style={{
                    height: "15vh",
                    fontSize: "2vh"
                }} />
                <SubmitButton loading={isLoading} onClick={() => {
                    submitRequest();
                }} style={{
                    width: "100%",
                    marginTop: 0
                }}>Create Goal</SubmitButton>
            </ModalInteriorContainer>
        </AddClassContainer >
    )
};

export default AddGoal;