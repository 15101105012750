import styled from 'styled-components';

export const HeaderIcon = styled.img`
    width: 1.5vw;
`;

export const LandingHero = styled.img`
    width: 60vw;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 3vh;
`;

export const FeatureIcon = styled.img`
    width: 3vw;
`;

export const RoleIcon = styled.img`
    width: 3vw;
    height: 3vw;
`;