import React from 'react';
import { useNavigate } from 'react-router-dom';

import { FaTimes } from 'react-icons/fa';
import { BiHomeAlt } from 'react-icons/bi';

import { ClassCardContainer, ClassIconBadgeContainer, ClassTitleContainer } from '../../primitives/containers';
import { ClassTitleText, ClassMemberCountText } from '../../primitives/typography';
import { RemoveClassButton } from '../../primitives/interactions';


interface ClassCardProps {
    class_id: string,
    symbol: string,
    member_count?: number
    color: String
    mode: String | null
    instructor?: String | null
    key?: number
};

const ClassCard = ({ ...props }: ClassCardProps) => {
    const navigate = useNavigate();

    const role = window.localStorage.getItem('_role');

    return (
        <ClassCardContainer onClick={() => {
            navigate("/class/" + props.class_id);
            {/* @ts-ignore */ }
        }} style={{ backgroundColor: props.color }}>
            <div style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
            }}>
                <ClassIconBadgeContainer>
                    <BiHomeAlt />
                </ClassIconBadgeContainer>
            </div>

            <ClassTitleContainer>
                <ClassTitleText>{props.symbol}</ClassTitleText>
                {(role === "instructor") ?
                    <ClassMemberCountText>{props.member_count} Members</ClassMemberCountText>
                    : <ClassMemberCountText>{props.instructor}</ClassMemberCountText>}
            </ClassTitleContainer>
        </ClassCardContainer>
    );
};

export default ClassCard;