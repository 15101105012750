import React from 'react';

import { BiMessageAltDetail } from 'react-icons/bi';
import { FaUserAlt } from 'react-icons/fa';
import { IoMdSchool } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

// @ts-ignore
import DefaultPFP from '../../../assets/default_pfp.jpg';
import { S3_URL } from '../../../constants';

import { PageHeaderContainer } from '../../primitives/containers';
import { PageHeaderButton } from '../../primitives/interactions';
import { GenericText } from '../../primitives/typography';

interface MemberHeaderProps {
    name: String
    email: String
    role?: String
    pfp?: String
    channels?: Array<any>
};

const MemberHeader = ({ ...props }: MemberHeaderProps) => {
    const navigate = useNavigate();

    return (
        <PageHeaderContainer style={{
            position: "fixed",
            width: "70vw",
            boxSizing: "border-box",
            backgroundColor: "white",
            paddingTop: "20vh",
            zIndex: 1
        }}>
            <div style={{
                display: "flex",
                alignItems: "center",
                gap: "1vh"
            }}>
                <img style={{
                    width: "4vw",
                    height: "4vw",
                    borderRadius: "100vh"
                }} src={S3_URL + props.pfp} />
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "0.5vw"
                }}>
                    <GenericText style={{ fontWeight: "bold", display: "flex", alignItems: "center", gap: "0.5vw" }}>
                        {(window.localStorage.getItem("_role") === "student") ? <IoMdSchool color={"#6EA8C8"} /> : <FaUserAlt color={"#6EA8C8"} />}
                        {props.name}
                    </GenericText>
                    <GenericText style={{ color: "gray" }}>{props.email}</GenericText>
                </div>
            </div>

            <PageHeaderButton onClick={() => {
                // @ts-ignore
                navigate("/messages/channel/" + props.channels[0].channel_id);
            }} style={{
                color: "#6EA8C8"
            }}>
                <BiMessageAltDetail />
                Message
            </PageHeaderButton>
        </PageHeaderContainer>
    )
};

export default MemberHeader;