import React from 'react';
import { useNavigate } from 'react-router-dom';

import { SectionContainer, SignUpFormContainer } from '../../../../components/primitives/containers';
import { HeaderIcon } from '../../../../components/primitives/images';

// @ts-ignore
import Logo from '../../../../assets/logo.svg';
import { FeatureDescription, FeatureTitle } from '../../../../components/primitives/typography';
import { SubmitButton } from '../../../../components/primitives/interactions';

const Success = () => {
    const navigate = useNavigate();

    return (
        <SectionContainer style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <SignUpFormContainer>
                <HeaderIcon style={{
                    width: "3vw"
                }} src={Logo} />
                <FeatureTitle style={{
                    fontSize: "5vh"
                }}>You're good to go</FeatureTitle>
                <FeatureDescription>
                    Get started with your new classroom.
                </FeatureDescription>
                <SubmitButton onClick={() => { window.location.href = "/"; window.location.reload(); }}>Go to your dashboard</SubmitButton>
            </SignUpFormContainer>
        </SectionContainer>
    );
};

export default Success;