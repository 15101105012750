import React, { useState } from 'react';
import { SectionContainer, SignUpFormContainer } from '../../../../components/primitives/containers';
import { HeaderIcon } from '../../../../components/primitives/images';

// @ts-ignore
import Logo from '../../../../assets/logo.svg';
import { FeatureDescription, FeatureTitle } from '../../../../components/primitives/typography';
import { AuthInput, SubmitButton } from '../../../../components/primitives/interactions';

interface PasswordProps {
    incrementIndex: Function
    decrementIndex: Function
    setFinalPassword: Function
};

const Password = ({ ...props }: PasswordProps) => {
    const {
        incrementIndex,
        decrementIndex,
        setFinalPassword
    } = props;

    const [password, setPassword] = useState(null);
    const [password2, setPassword2] = useState(null);
    const [passwordStatus, setPasswordStatus] = useState(true);
    const [password2Status, setPassword2Status] = useState(true);

    const SubmitPassword = () => {
        if (password !== password2) {
            setPassword2Status(false);
        } else {
            let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/
            // @ts-ignore
            if (regex.test(password)) {
                setPassword2Status(true);
                incrementIndex();
                setFinalPassword(password);
            } else {
                setPasswordStatus(false);
                setPassword2Status(false);
            }
        }
    };

    return (
        <SectionContainer style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <SignUpFormContainer>
                <HeaderIcon style={{
                    width: "3vw"
                }} src={Logo} />
                <FeatureTitle style={{
                    fontSize: "5vh"
                }}>Choose your password</FeatureTitle>
                <FeatureDescription style={{
                    fontSize: "2vh",
                    width: "30vw"
                }}>
                    Must be at least 8 characters and contain at least one symbol, capital letter, and digit.
                </FeatureDescription>
                {/* @ts-ignore */}
                <AuthInput type={"password"} status={passwordStatus} onChange={(e) => { setPassword(e.target.value) }} style={{ marginTop: "4vh" }} placeholder={"Enter your password..."} />
                {/* @ts-ignore */}
                <AuthInput type={"password"} status={password2Status} onChange={(e) => { setPassword2(e.target.value) }} style={{ marginTop: "2vh" }} placeholder={"Re-enter your password..."} />
                <SubmitButton onClick={SubmitPassword}>Continue</SubmitButton>
            </SignUpFormContainer>
        </SectionContainer>
    );
};

export default Password;