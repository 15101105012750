import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import AppNavigation from '../../components/nav/app';
import ClassCard from '../../components/composite/cards/class';

import Drawer from '../../components/composite/drawer';

import FourOhFourPage from '../404';

import { GenericText } from '../../components/primitives/typography';

import { useFindClassMembersQuery, useGetClassQuery, useRemoveClassMutation, useRemoveMemberMutation } from '../../state/api';

import Goals from '../../components/composite/goals';

import { ClassesContainer, MainContainer, ContentContainer, ContentInterior, MembersContainer } from '../../components/primitives/containers';
import AppHeader from '../../components/composite/headers/appheader';
import PageHeader from '../../components/composite/headers/pageheader';
import { useFindClassMutation } from '../../state/api';
import StudentTable from '../../components/composite/studenttable';
import Modal from '../../components/composite/modal';
import MemberHeader from '../../components/composite/headers/memberheader';
import Feedback from '../../components/composite/feedback';

import { Oval } from 'react-loader-spinner';

const StudentView = () => {
    const [drawerType, setDrawerType] = useState("");
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerData, setDrawerData] = useState(null);
    const [modalType, setModalType] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);

    const { class_id } = useParams();

    const { data, isLoading, isSuccess } = useGetClassQuery({
        class_id
    });

    const signOut = () => {
        // @ts-ignore
        setModalTitle("Sign Out");
        // @ts-ignore
        setModalType("confirmation");
        setModalOpen(true);
    }

    const closeModal = () => {
        setModalOpen(false);
    }

    const SignOut = () => {
        window.localStorage.removeItem("_token");
        window.localStorage.removeItem("_name");
        window.localStorage.removeItem("_email");
        window.localStorage.removeItem("_role");

        window.location.reload();
        window.location.href = "/";
    }

    const openNotificationDrawer = (unread_feedback: Array<Object>, unread_messages: Array<Object>) => {
        // @ts-ignore
        setDrawerData({ unread_feedback, unread_messages });
        setDrawerType("notifications");
        setDrawerOpen(true);
    }

    const closeNotificationDrawer = () => {
        setDrawerData(null);
        setDrawerType("");
        setDrawerOpen(false);
    }
    return (
        <MainContainer style={{
            display: "flex"
        }}>
            <AppNavigation signOut={signOut} page={"home"} />

            <ContentContainer>
                <AppHeader openNotificationDrawer={openNotificationDrawer} />
                {
                    (isSuccess) ?
                        ((data.data.length > 0) ?
                            <ContentInterior style={{
                                paddingTop: 0,
                                position: "relative"
                            }}>
                                <PageHeader
                                    className={data.data[0].name}
                                    classSymbol={data.data[0].symbol}
                                    classColor={data.data[0].color}
                                    page={"class"}
                                />
                                <div style={{
                                    width: "100%",
                                    display: "flex",
                                    marginTop: "2vh",
                                    boxSizing: "border-box",
                                    gap: "3vw",
                                    position: "relative"
                                }}>
                                    <Goals
                                        // @ts-ignore
                                        class_id={class_id}
                                        // @ts-ignore
                                        student_id={window.localStorage.getItem("_user_id")}
                                    />

                                    <div style={{
                                        width: "40vw",
                                        height: "59vh",
                                        backgroundColor: "whitesmoke",
                                        position: "absolute",
                                        right: 0,
                                        top: "32vh",
                                        borderRadius: "2vh",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}>
                                        <GenericText style={{
                                            color: "gray"
                                        }}>Goal not selected.</GenericText>
                                    </div>
                                </div>
                            </ContentInterior>
                            : <FourOhFourPage />)
                        : (isLoading) ? <ContentInterior style={{
                            height: "50vw",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <Oval
                                height={40}
                                width={40}
                                color="#6EA8C8"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="white"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                            />
                        </ContentInterior>
                            : <></>
                }

            </ContentContainer>

            <Modal
                open={modalOpen}
                type={modalType}
                closeModal={closeModal}
                signOut={SignOut}
                // @ts-ignore
                title={modalTitle}
            />

            <Drawer
                type={drawerType}
                open={drawerOpen}
                close={closeNotificationDrawer}
                data={drawerData}
            />
        </MainContainer>
    );
};

const TeacherView = () => {
    const [drawerType, setDrawerType] = useState("");
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerData, setDrawerData] = useState(null);
    const [modalType, setModalType] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [memberId, setMemberId] = useState(null);

    const { class_id } = useParams();

    const { data, isLoading, isSuccess } = useGetClassQuery({
        class_id
    });

    const [removeMemberMut] = useRemoveMemberMutation();

    const signOut = () => {
        // @ts-ignore
        setModalTitle("Sign Out");
        // @ts-ignore
        setModalType("confirmation");
        setModalOpen(true);
    }

    const closeModal = () => {
        setMemberId(null);
        setModalOpen(false);
    }

    const RemoveClass = () => {
        // @ts-ignore
        setModalTitle("Remove Class");
        // @ts-ignore
        setModalType("confirmation");
        setModalOpen(true);
    };

    const AddMember = () => {
        // @ts-ignore
        setModalType("add_member");
        setModalOpen(true);
    }

    const SignOut = () => {
        window.localStorage.removeItem("_token");
        window.localStorage.removeItem("_name");
        window.localStorage.removeItem("_email");
        window.localStorage.removeItem("_role");

        window.location.reload();
        window.location.href = "/";
    };

    const removeMember = (member_id: String) => {
        console.log(member_id);

        // @ts-ignore
        setModalTitle("Remove Member");
        // @ts-ignore
        setMemberId(member_id);
        // @ts-ignore
        setModalType("confirmation");
        setModalOpen(true);
    };

    const RemoveMember = async () => {
        const results = await removeMemberMut({
            class_id,
            member_id: memberId
        });

        // @ts-ignore
        if (results.data.data.error) {
            closeModal();
        } else {
            window.location.reload();
            closeModal();
        }
    };

    const openNotificationDrawer = (unread_feedback: Array<Object>, unread_messages: Array<Object>) => {
        // @ts-ignore
        setDrawerData({ unread_feedback, unread_messages });
        setDrawerType("notifications");
        setDrawerOpen(true);
    }

    const closeNotificationDrawer = () => {
        setDrawerData(null);
        setDrawerType("");
        setDrawerOpen(false);
    }

    const removeClass = async () => {

        // @ts-ignore
        // const results = await removeClass({ class_id });
        console.log(class_id);
    };
    return (
        <MainContainer style={{
            display: "flex"
        }}>
            <AppNavigation signOut={signOut} page={"home"} />
            <ContentContainer>
                <AppHeader openNotificationDrawer={openNotificationDrawer} />
                {
                    (isSuccess) ?
                        ((data.data.length > 0) ?
                            <ContentInterior>
                                <PageHeader addMember={AddMember} removeClass={RemoveClass} page={"class"} className={data.data[0].name} classColor={data.data[0].color} classSymbol={data.data[0].symbol} instructorCode={data.data[0].instructor_code} studentCode={data.data[0].student_code} />
                                <StudentTable removeMember={removeMember} class_id={data.data[0].class_id} />
                                <Modal
                                    open={modalOpen}
                                    type={modalType}
                                    closeModal={closeModal}
                                    removeClass={removeClass}
                                    joinCode={data.data[0].join_code}
                                    classId={data.data[0].class_id}
                                    signOut={SignOut}
                                    removeMember={RemoveMember}
                                    // @ts-ignore
                                    title={modalTitle}
                                />
                            </ContentInterior>
                            : <></>)
                        :
                        (isLoading) ?
                            <ContentInterior style={{
                                width: "100%",
                                height: "100vh",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                                <Oval
                                    height={40}
                                    width={40}
                                    color="#6EA8C8"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                    ariaLabel='oval-loading'
                                    secondaryColor="white"
                                    strokeWidth={2}
                                    strokeWidthSecondary={2}
                                />
                            </ContentInterior>
                            : <></>
                }
            </ContentContainer>
            <Drawer
                type={drawerType}
                open={drawerOpen}
                close={closeNotificationDrawer}
                data={drawerData}
            />
        </MainContainer>
    );
};

const ClassRoom = () => {
    const role = window.localStorage.getItem("_role");

    return (role === "instructor") ? <TeacherView /> : <StudentView />;
};

export default ClassRoom;