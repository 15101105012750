import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route
} from 'react-router-dom';

// Landing Routes
import LandingHome from './pages/landing/home';
import SignIn from './pages/landing/signin';
import Registration from './pages/landing/registration';

// Application Routes
import AppHome from './pages/app/home';
import AppMessages from './pages/app/messages';
import AppClass from './pages/app/class';

// Misc Routes
import FourOhFourPage from './pages/404';
import Student from './pages/app/student';
import Goal from './pages/app/goal';
import Preferences from './pages/app/preferences';
import Channel from './pages/app/channel';

const LandingRouter = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<LandingHome />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/registration" element={<Registration />} />
                <Route path="*" element={<FourOhFourPage />} />
            </Routes>
        </BrowserRouter>
    );
};

const AppRouter = () => {
    const role = window.localStorage.getItem("_role");

    if (role === "instructor") {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<AppHome />} />
                    <Route path="/messages" element={<AppMessages />} />
                    <Route path="/messages/channel/:channel_id" element={<Channel />} />
                    <Route path="/preferences" element={<Preferences />} />
                    <Route path="/class/:class_id" element={<AppClass />} />
                    <Route path="/class/:class_id/join" element={<AppClass />} />
                    <Route path="/class/:class_id/student/:student_id" element={<Student />} />
                    <Route path="/class/:class_id/student/:student_id/goal/:goal_id" element={<Goal />} />
                    <Route path="*" element={<FourOhFourPage />} />
                </Routes>
            </BrowserRouter>
        )
    } else {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<AppHome />} />
                    <Route path="/messages" element={<AppMessages />} />
                    <Route path="/messages/channel/:channel_id" element={<Channel />} />
                    <Route path="/preferences" element={<Preferences />} />
                    <Route path="/class/:class_id" element={<AppClass />} />
                    <Route path="/class/:class_id/join" element={<AppClass />} />
                    <Route path="/class/:class_id/goal/:goal_id" element={<Goal />} />
                    <Route path="*" element={<FourOhFourPage />} />
                </Routes>
            </BrowserRouter>
        )
    }
};

const Router = () => {
    const token = window.localStorage.getItem("_token");

    if (token === undefined || token === null || token === "undefined") {
        return <LandingRouter />
    } else {
        return <AppRouter />
    }
};

export default Router;