import React, { useState } from 'react';
import ReactDrawer from 'react-modern-drawer';

import 'react-modern-drawer/dist/index.css'
import NotificationsView from './views/notifications';

interface DrawerProps {
    open?: Boolean
    viewNotification?: Function
    type?: String
    close?: Function
    data: Object | null
}

const Drawer = ({ ...props }: DrawerProps) => {
    const [open, setOpen] = useState(props.open);

    return (
        <ReactDrawer
            // @ts-ignore
            open={props.open}
            direction={"right"}
            style={{
                width: "20vw"
            }}
        >
            {/* @ts-ignore */}
            {(props.type === "notifications") ? <NotificationsView open={props.open} data={props.data} closeDrawer={props.close} /> : <></>}
        </ReactDrawer>
    )
};

export default Drawer;