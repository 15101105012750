import styled from 'styled-components';

interface ContainerProps {
    header?: boolean
    color?: string
};

export const MainContainer = styled.div`
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
`;

export const HeaderContainer = styled.div`
    width: 100vw;
    box-sizing: border-box;
    padding: 3vh;
    padding-left: 3vw;
    padding-right: 3vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    background-color: white;
`;

export const HeaderIconContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1vw;
`;

export const HeaderNavLinksContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1vw;
`;

export const SectionContainer = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    box-sizing: border-box;
`;

export const LandingHeroTextContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const SectionInteriorContainer = styled.div`
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const FeaturesHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const SignInFormContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const SignUpFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const PricingCardContainer = styled.div`
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;  
    display: flex;
    flex-direction: column;
    width: 25vw;
    padding: 3vh;
    box-sizing: border-box;
    margin-top: 5vh;
    border-radius: 2vh;
`;

export const AppNavContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    box-sizing: border-box;
    width: 20vw;
    gap: 1vh;
    border-right: 1px solid lightgray;
`;

export const ContentContainer = styled.div`
    flex: 1;
    height: 100vh;
    box-sizing: border-box;
    overflow-y: scroll;
    position: relative;
`;

export const ContentContainerInterior = styled.div`

`;

export const AppHeaderContainer = styled.div`
    width: 80%;
    box-sizing: border-box;
    position: fixed;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid lightgray;
    padding: 2vh;
    background-color: white;
    padding-right: 2vw;
`;

export const BadgeContainer = styled.div`
    font-family: Inter, sans-serif;
    background-color: #ECF8FF;
    padding-left: 1vw;
    padding-right: 1vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
    border-radius: 100vh;
    color: #344054;
`;

export const NotificationsButton = styled.button`
    background-color: white;
    border: none;
    outline: none;
    font-size: 3vh;
    margin-right: 2vw;
`;

export const ProfileButton = styled.button`
    background-color: white;
    outline: none;
    border: none;
    border-radius: 100vh;
    cursor: pointer;
`;

export const ContentInterior = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 10vh;
    padding-top: 17vh;
    display: flex;
    flex-direction: column;
    gap: 5vh;
`;

export const PageHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const ClassesContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 2vh;
    gap: 2vw;
`;

export const ClassCardContainer = styled.div`
    padding: 2vh;
    border-radius: 2vh;
    transition: all 0.3s ease 0.1s;
    cursor: pointer;

    &:hover {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;  
    }
`;

export const ClassIconBadgeContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(255,255,255, 0.5);
    width: 3vw;
    height: 3vw;
    border-radius: 1vh;
    font-size: 2vh;
`;

export const ClassTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2vh;
    border-radius: 2vh;
    align-items: flex-start;
    background-color: rgb(255,255,255, 0.5);
    margin-top: 5vh;
    gap: 1vh;
`;

export const ActivityContainer = styled.div`
    width: 100%;
    background-color: white;
    border: 1px solid lightgray;
    border-radius: 2vh;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; 
    display: flex;
    flex-direction: column;
    box-sizing: border-box; 
    gap: 5vh;
`;

export const ActivityHeaderContainer = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid lightgray;
`;

export const ButtonGroupContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1vw;
    background-color: whitesmoke;
    border-radius: 2vh;
`;

export const TableContainer = styled.div`
    width: 100%;
    box-sizing: border-box;
    border-radius: 2vh;
`;

export const ActivityRowContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid lightgray;
`;

export const ActivityRowCellContainer = styled.div<ContainerProps>`
    flex: 1;
    box-sizing: border-box;
    font-family: Inter, sans-serif;
    padding-bottom: 2vh;
    padding-top: 2vh;
    font-size: ${(props) => (props.header) ? "2vh" : "1.5vh"};
    height: 100%;
`;

export const TableRowContainer = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`;

export const ActivityBadgeContainer = styled.div`
    width: 2.5vw;
    height: 2.5vw;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2vh;
    border-radius: 100vh;
`;

export const AddClassContainer = styled.div`
    width: 30vw;
    padding: 3vh;
    background-color: white;
    border-radius: 2vh;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;  
`;

export const JoinClassContainer = styled.div`
    width: 30vw;
    padding: 3vh;
    background-color: white;
    border-radius: 2vh;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;  
`;

export const SignOutContainer = styled.div`
    width: 40vw;
    background-color: white;
    padding: 3vh;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;  
    border-radius: 2vh;
`;

export const ModalHeaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

export const ModalInteriorContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const ClassIconBadgeContainerLarge = styled.div<ContainerProps>`
    width: 100%;
    height: 15vw;
    border-radius: 5vh;
    background-color: ${(props) => props.color};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10vh;
    color: rgb(0, 0, 0, 0.5);
`;

export const MembersContainer = styled.div`
    width: 100%;
    border: 1px solid lightgray;
    height: 50vh;
    border-radius: 2vh;
`;

export const CodeContainer = styled.div`
    display: flex;
    padding: 1.5vh;
    box-sizing: border-box;
    background-color: #ECF8FF;
    color: #6EA8C8;
    border-radius: 2vh;
    width: 100%;
    flex-direction: column;
    font-size: 3vh;
    font-family: Inter, sans-serif;
`;

export const CodeContentContainer = styled.div`
    padding: 1.5vh;
    background-color: #adcadb;
    border-radius: 1vh;
    align-items: center;
    display: flex;
`;

export const StudentTableHeaderContainer = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background-color: whitesmoke;
    padding: 2vh;
    border-top-left-radius: 2vh;
    border-top-right-radius: 2vh;
`;

export const StudentRowCellContainer = styled.div<ContainerProps>`
    flex: 1;
    box-sizing: border-box;
    font-family: Inter, sans-serif;
    font-size: ${(props) => (props.header) ? "2vh" : "1.5vh"};
    height: 100%;
    color: gray;
`;

export const RemoveClassContainer = styled.div`
    width: 40vw;
    background-color: white;
    padding: 3vh;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;  
    border-radius: 2vh;
`;

export const AddMemberContainer = styled.div`
    width: 40vw;
    background-color: white;
    padding: 3vh;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;  
    border-radius: 2vh;
`;

export const RowContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;

export const Placeholder = styled.div`
    height: 2vh;
    background-color: whitesmoke;
    border: 2vh;
    width: 10vw;
    border-radius: 1vh;
`;

export const GoalsContainer = styled.div`
    width: 25vw;
    display: flex;
    flex-direction: column;
    position: fixed;
    margin-top: 32vh;
    box-sizing: border-box;
`;

export const FeedbackContainer = styled.div`
    background-color: white;
    border-radius: 2vh;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    gap: 4vh;
    position: absolute;
    right: 0;
    margin-top: 32vh;
    z-index: 0;
    width: 40vw;
    padding-bottom: 5vh;
`;

export const GoalsInterior = styled.div`
    width: 100%;
    background-color: whitesmoke;
    margin-top: 2vh;
    border-radius: 2vh;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1vh;
    align-content: flex-start;
`;

export const GoalBadgeContainer = styled.div`
    width: 4vw;
    height: 4vw;
    border-radius: 2vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.5vh;
    color: rgb(0, 0, 0, 0.25)
`;

export const GoalCardContainer = styled.div<ContainerProps>`
    width: 7vw;
    height: 7vw;
    background-color: ${(props) => props.color};
    border-radius: 2vh;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease 0.1s;
    position: relative;

    &:hover {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; 
    }
`;

export const GivenFeedbackContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 3vh;
    border: 2px solid lightgray;
    border-radius: 2vh;
`;

export const SettingCardContainer = styled.div`
    width: 100%;
    box-sizing: border-box;
    border: 1px solid lightgray;
    border-radius: 2vh;
    background-color: white;
`;

export const SettingContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 3vh;
    background-color: white;
    gap: 2vh;
`;

export const ChatWindowContainer = styled.div`
    width: 100%;
    height: 100%;
    background-color: transparent;
    overflow-x: hidden;
    position: relative;
`;

export const ChatInputContainer = styled.div`
    width: 90%;
    background-color: white;
    bottom: 0;
    position: absolute;
    left: 5%;
    box-sizing: border-box;
    bottom: 4vh;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 2vh;
    display: flex;
    justify-content: center;
`;

export const ChatMessageContainer = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
`;

export const MessageBubbleContainer = styled.div`
    width: max-content;
    padding: 2vh;
    border-radius: 3vh;
    font-family: Inter, sans-serif;
    font-size: 1.5vh;
    max-width: 20vw;
    word-wrap: break-word;
`;

export const NotificationBadgeContainer = styled.span`
    width: 1.5vw;
    height: 1.5vw;
    background-color: #6EA8C8;
    color: white;
    border-radius: 100vh;
    font-size: 1vh;
    position: absolute;
    top: -1vh;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -0.25vw;
`;