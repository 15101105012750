import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { PricingCardContainer } from '../../primitives/containers';
import { SubmitButton } from '../../primitives/interactions';
import { GenericText } from '../../primitives/typography';
import Badge from '../badge';

interface PricingCardProps {
    price: number
    description: string
    features: Array<String>
    tier: String
};

const PricingCard = ({ ...props }: PricingCardProps) => {
    const {
        price,
        description,
        features,
        tier
    } = props;

    const navigate = useNavigate();

    return (
        <PricingCardContainer>
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
            }}>
                <GenericText>User Plan</GenericText>
                <Badge
                    content={"Free"}
                />
            </div>
            <div style={{
                width: "100%",
                marginTop: "3vh",
                marginBottom: "3vh"
            }}>
                <GenericText style={{
                    fontSize: "10vh"
                }}>$0</GenericText>
                <GenericText style={{
                    fontSize: "3vh",
                    marginLeft: "0.5vw",
                    color: "gray"
                }}>per month</GenericText>
            </div>
            <GenericText style={{ color: "gray" }}>Built for teachers and students alike.</GenericText>

            <SubmitButton style={{
                width: "100%"
            }}>
                Get Started
            </SubmitButton>

            <div style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                marginTop: "4vh",
            }}>
                <GenericText style={{
                    display: "flex",
                    gap: "1vw",
                    alignItems: "center",
                    color: "gray"
                }}>
                    <span style={{ fontSize: "3vh" }}><FaCheckCircle color={"#6ea8cb"} /></span>
                    Access to everything
                </GenericText>
                <GenericText style={{
                    display: "flex",
                    gap: "1vw",
                    alignItems: "center",
                    color: "gray"
                }}>
                    <span style={{ fontSize: "3vh" }}><FaCheckCircle color={"#6ea8cb"} /></span>
                    Unlimited Students
                </GenericText>
                <GenericText style={{
                    display: "flex",
                    gap: "1vw",
                    alignItems: "center",
                    color: "gray"
                }}>
                    <span style={{ fontSize: "3vh" }}><FaCheckCircle color={"#6ea8cb"} /></span>
                    Free Updates
                </GenericText>
            </div>
        </PricingCardContainer >
    );
};

export default PricingCard;