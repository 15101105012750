import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import ClassCard from './cards/class';

import { ClassesContainer } from '../primitives/containers';
import { useFindAllClassesQuery } from '../../state/api';
import { LandingHeroHeader } from '../primitives/typography';

import { Oval } from 'react-loader-spinner';

interface ClassesProps {
    mode: String | null
}

const Classes = ({ ...props }: ClassesProps) => {
    const {
        mode
    } = props;

    const role = window.localStorage.getItem("_role");

    const navigate = useNavigate();

    // @ts-ignore
    const { data, isLoading, isSuccess } = useFindAllClassesQuery();

    if (isSuccess) {
        const classComponents = data.data.map((c: any, i: number) => {
            if (role === "instructor") {
                return <ClassCard
                    mode={mode}
                    class_id={c.class_id}
                    symbol={c.symbol}
                    member_count={(role === "instructor") ? c.instructors.length + c.students.length : c.instructors.length}
                    color={c.color}
                    key={i}
                />
            } else {
                return <ClassCard
                    mode={mode}
                    class_id={c.class_id}
                    symbol={c.symbol}
                    instructor={c.instructors[0].name}
                    color={c.color}
                    key={i}
                />
            }
        });

        return (
            <ClassesContainer>
                {(data.data.length === 0) ?
                    <div style={{
                        width: "100%",
                        paddingTop: "20vh",
                        paddingBottom: "20vh",
                        backgroundColor: "whitesmoke",
                        borderRadius: "2vh",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center"
                    }}>
                        <LandingHeroHeader style={{ width: "max-content", color: "darkgray", fontSize: "3vh" }}>Looks like you don't have any classes.</LandingHeroHeader>
                    </div>
                    : <ClassesContainer style={{
                        display: "flex",
                        flexWrap: "wrap"
                    }}>
                        {classComponents}
                    </ClassesContainer>
                }
            </ClassesContainer>
        );
    } else {
        return (
            <ClassesContainer style={{
                display: "flex",
                alignItems: 'center',
                justifyContent: "center",
                width: "100%",
                height: "50vh"
            }}>
                <Oval
                    height={40}
                    width={40}
                    color="#6EA8C8"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="white"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
            </ClassesContainer>
        )
    }
};

export default Classes;