import React, { useState, useEffect } from 'react';
import { CirclePicker, SketchPicker, SwatchesPicker } from 'react-color';

import { FaTimes } from 'react-icons/fa';
import { BiHomeAlt } from 'react-icons/bi';

import { AddClassContainer, ClassIconBadgeContainerLarge, ModalHeaderContainer, ModalInteriorContainer } from '../../../primitives/containers';
import { ExitButton, GenericInput, SubmitButton } from '../../../primitives/interactions';
import { ModalHeaderTitle } from '../../../primitives/typography';
import { useCreateClassMutation, useFindAllClassesQuery, useFindClassMutation } from '../../../../state/api';

interface AddClassProps {
    closeModal: Function
};

const AddClass = ({ ...props }: AddClassProps) => {
    const [className, setClassName] = useState("");
    const [classSymbol, setClassSymbol] = useState("");
    const [nameStatus, setNameStatus] = useState(true);
    const [symbolStatus, setSymbolStatus] = useState(true);
    const [color, setColor] = useState(null);

    const [findClass, { data: classesData, isLoading: isFindLoading, isSuccess: isFindSuccess }] = useFindClassMutation();
    const [createClassCall, { data: createdClassData, isLoading: isCreateLoading, isSuccess: isCreateSuccess }] = useCreateClassMutation();

    const colors = ["#e5c3c6", "#e1e9b7", "#f96161", "#bcd2d0", "#d0b783", "#363b74", "#673888"]

    const chooseColor = () => {
        return colors[Math.floor(Math.random() * colors.length)];
    }

    useEffect(() => {
        const color = chooseColor();
        // @ts-ignore
        setColor(color);
    }, []);

    const createClass = async () => {
        let regex = /^[a-zA-Z0-9 ]{3,20}$/

        // @ts-ignore
        if (regex.test(className) && regex.test(classSymbol) && className !== "" && classSymbol !== "") {
            const created_class = await createClassCall({
                name: className,
                symbol: classSymbol,
                color: color
            });

            // @ts-ignore
            if (created_class.data.error) {
                setNameStatus(false);
                setSymbolStatus(false);
            } else {
                props.closeModal();
                window.location.reload();
            }
        } else {
            // @ts-ignore
            if (!regex.test(className) || className === "") {
                setNameStatus(false)
            }

            // @ts-ignore
            if (!regex.test(classSymbol) || classSymbol === "") {
                setSymbolStatus(false);
            }
        }
    };

    return (
        <AddClassContainer>
            <ModalHeaderContainer>
                <ModalHeaderTitle style={{ fontSize: "2.5vh" }}>Add Class</ModalHeaderTitle>

                <ExitButton onClick={() => {
                    props.closeModal();
                }}>
                    <FaTimes />
                </ExitButton>
            </ModalHeaderContainer>

            <ModalInteriorContainer style={{
                alignItems: "center"
            }}>
                <div style={{
                    marginTop: "5vh",
                    width: "100%",
                    display: "flex",
                    gap: "4vh",
                    alignItems: "center"
                }}>
                    {/* @ts-ignore */}
                    <ClassIconBadgeContainerLarge color={color}>
                        <BiHomeAlt />
                    </ClassIconBadgeContainerLarge>
                    <CirclePicker onChange={(color, e) => {
                        // @ts-ignore
                        setColor(color.hex);
                    }} />
                </div>
                <div style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    boxSizing: "border-box",
                    marginTop: "5vh",
                    gap: "2vh"
                }}>
                    <GenericInput status={nameStatus} onChange={(e: any) => {
                        // @ts-ignore
                        setClassName(e.target.value);
                    }} placeholder={"Class Name..."} style={{
                        width: "100%",
                        fontSize: "2vh"
                    }} />
                    <GenericInput status={symbolStatus} onChange={(e: any) => {
                        // @ts-ignore
                        setClassSymbol(e.target.value);
                    }} placeholder={"Class Abbreviation; ex: ENG101"} style={{
                        width: "100%",
                        fontSize: "2vh"
                    }} />
                    <SubmitButton loading={isCreateLoading} onClick={() => {
                        createClass();
                    }} style={{
                        width: "100%",
                        marginTop: 0
                    }}>Create Class</SubmitButton>
                </div>
            </ModalInteriorContainer>
        </AddClassContainer >
    );
};

export default AddClass;