import React, { useRef, useState } from 'react';
import AppHeader from '../../components/composite/headers/appheader';
import AppNavigation from '../../components/nav/app';
import { ContentContainer, ContentInterior, MainContainer, PageHeaderContainer } from '../../components/primitives/containers';

import Modal from '../../components/composite/modal';
import Drawer from '../../components/composite/drawer';

import { GenericText, PageHeaderDescription, PageHeaderTitle } from '../../components/primitives/typography';
import Setting from '../../components/composite/cards/setting';
import { SubmitButton } from '../../components/primitives/interactions';
import { useFindMeMutation, useUpdateImageMutation } from '../../state/api';
import { S3_URL } from '../../constants';

const Preferences = () => {
    const [drawerType, setDrawerType] = useState("");
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerData, setDrawerData] = useState(null);
    const [modalType, setModalType] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [classMode, setClassMode] = useState(null);
    const [profileHover, setProfileHover] = useState(false);
    const [file, setFile] = useState(null);
    const [profileImage, setProfileImage] = useState(S3_URL + window.localStorage.getItem("_pfp"));

    const [updateImage] = useUpdateImageMutation();
    const [findMe] = useFindMeMutation();

    const fileRef = useRef();

    const closeModal = () => {
        setClassMode(null);
        setModalOpen(false);
    };

    const signOut = () => {
        // @ts-ignore
        setModalType("confirmation");
        // @ts-ignore
        setModalTitle("Sign Out");
        setModalOpen(true);
    };

    const SignOut = () => {
        window.localStorage.removeItem("_token");
        window.localStorage.removeItem("_name");
        window.localStorage.removeItem("_email");
        window.localStorage.removeItem("_role");

        window.location.reload();
        window.location.href = "/";
    };

    const onChange = (e: any) => {
        const fileUploaded = e.target.files[0];
        let reader = new FileReader();

        // @ts-ignore
        setFile(fileUploaded);
        // @ts-ignore
        setProfileImage(URL.createObjectURL(e.target.files[0]));
    };

    const changeImage = async () => {
        const form = new FormData();

        // @ts-ignore
        form.append("file", file);

        const results = await updateImage(form);

        // @ts-ignore
        if (results.data.data) {
            const updated_user = await findMe({
                // @ts-ignore
                authorization: window.localStorage.getItem("_token")
            });

            // @ts-ignore
            window.localStorage.setItem("_pfp", updated_user.data.data.pfp);
            window.location.reload();
        } else {
            setProfileImage(S3_URL + window.localStorage.getItem("_pfp"));
            window.location.reload();
        }
    };

    const openNotificationDrawer = (unread_feedback: Array<Object>, unread_messages: Array<Object>) => {
        // @ts-ignore
        setDrawerData({ unread_feedback, unread_messages });
        setDrawerType("notifications");
        setDrawerOpen(true);
    }

    const closeNotificationDrawer = () => {
        setDrawerData(null);
        setDrawerType("");
        setDrawerOpen(false);
    }

    return (
        <MainContainer style={{
            display: "flex"
        }}>
            <AppNavigation signOut={signOut} page={"home"} />
            <ContentContainer>
                <AppHeader openNotificationDrawer={openNotificationDrawer} />
                <ContentInterior style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <PageHeaderContainer style={{
                        display: "flex",
                        justifyContent: "left",
                        width: "100%"
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "column"
                        }}>
                            <PageHeaderTitle>Preferences</PageHeaderTitle>
                            <PageHeaderDescription>Change your settings here.</PageHeaderDescription>
                        </div>
                    </PageHeaderContainer>

                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        alignItems: "center"
                    }}>
                        <div style={{
                            width: "60%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "6vh"
                        }}>
                            <div style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                                alignItems: "flex-start"
                            }}>
                                <div style={{
                                    width: "100%",
                                    display: "flex",
                                    gap: "1vw",
                                    alignItems: "center"
                                }}>
                                    <div onMouseOut={() => {
                                        setProfileHover(false);
                                    }} onMouseOver={() => {
                                        setProfileHover(true);
                                    }} style={{
                                        position: "relative"
                                    }}>
                                        <img style={{
                                            width: "7vw",
                                            height: "7vw",
                                            borderRadius: "100vh"
                                        }} src={profileImage} />
                                        {(profileHover) ?
                                            <div onClick={() => {
                                                // @ts-ignore
                                                fileRef.current.click();
                                            }} style={{
                                                backgroundColor: "rgb(0, 0, 0, 0.25)",
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                width: "7vw",
                                                height: "7vw",
                                                borderRadius: "100vh",
                                                cursor: "pointer",
                                                transition: "all 0.3s ease 0.1s"
                                            }}>

                                            </div>
                                            : <></>}
                                    </div>
                                    <div style={{
                                        height: "100%",
                                        display: "flex",
                                        flexDirection: "column"
                                    }}>
                                        <GenericText style={{
                                            fontWeight: "bold",
                                            fontSize: "3vh"
                                        }}>{window.localStorage.getItem('_name')}</GenericText>
                                        <GenericText>{window.localStorage.getItem('_email')}</GenericText>
                                    </div>
                                    {/* @ts-ignore */}
                                    <input onChange={(e) => {
                                        onChange(e)
                                        // @ts-ignore
                                    }} ref={fileRef} style={{ display: "none" }} type={"file"} />
                                </div>
                                {(profileImage !== S3_URL + window.localStorage.getItem("_pfp")) ? <SubmitButton onClick={() => {
                                    changeImage();
                                }} style={{
                                    width: "max-content"
                                }}>
                                    Submit Change
                                </SubmitButton> : <></>}
                            </div>
                            <Setting />
                        </div>
                    </div>
                </ContentInterior>
                <Modal
                    open={modalOpen}
                    type={modalType}
                    closeModal={closeModal}
                    // @ts-ignore
                    title={modalTitle}
                    signOut={SignOut}
                />
                <Drawer 
                    type={drawerType}
                    open={drawerOpen}
                    close={closeNotificationDrawer}
                    data={drawerData}
                />
            </ContentContainer>
        </MainContainer>
    )
};

export default Preferences;