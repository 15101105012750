import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { REST_API_URL } from '../constants';

interface SignInResponse {
    authorization: String
};

interface SignInRequest {
    email: String,
    password: String
};

interface SignUpRequest {
    email: String
    name: String
    password: String
    role: String
};

interface FindMeRequest {
    authorization: String
};

interface FindMeResponse {
    email: String,
    name: String,
    role: String
};

interface CreateClassRequest {

};

interface CreateClassResponse {

};

interface FindClassRequest {

}

interface FindClassResponse {

}

interface RemoveClassRequest {
    class_id: String
};

interface RemoveClassResponse {

};

interface UpdateGoalResponse {

}

interface UpdateGoalRequest {

};

interface CreateFeedbackRequest {

};

interface CreateFeedbackResponse {

};

interface JoinClassRequest {
    class_id: String
    join_code: String
};

interface JoinClassResponse {

};

interface CreateGoalRequest {

}

interface CreateGoalResponse {

};

interface RemoveGoalRequest {

}

interface RemoveGoalResponse {

};

interface RemoveMemberRequest {

}

interface RemoveMemberResponse {

};

interface RemoveFeedbackRequest {

}

interface RemoveFeedbackResponse {

};

export const api = createApi({
    reducerPath: "api",
    baseQuery: fetchBaseQuery({ baseUrl: REST_API_URL, mode: 'cors' }),
    endpoints: (builder) => ({
        // Sign In Request
        signIn: builder.mutation<SignInResponse, Partial<SignInRequest>>({
            // @ts-ignore
            query(signin_request) {
                return {
                    url: "auth/signin",
                    method: "POST",
                    body: signin_request
                }
            }
        }),

        // Sign Up Request
        signUp: builder.mutation<SignInResponse, Partial<SignUpRequest>>({
            // @ts-ignore
            query(signup_request) {
                return {
                    url: "auth/registration",
                    method: "POST",
                    body: signup_request
                }
            }
        }),

        // Find Me Request
        findMe: builder.mutation<FindMeResponse, Partial<FindMeRequest>>({
            // @ts-ignore
            query(find_me_request) {
                return {
                    url: "user/me",
                    method: "GET",
                    headers: {
                        authorization: find_me_request.authorization
                    }
                }
            }
        }),

        // Find Classes Request
        findAllClasses: builder.query({
            // @ts-ignore
            query: () => {
                return {
                    url: "classes/find",
                    headers: {
                        authorization: window.localStorage.getItem("_token")
                    }
                }
            }
        }),

        // Create Class
        createClass: builder.mutation<CreateClassResponse, Partial<CreateClassRequest>>({
            // @ts-ignore
            query(create_class_request) {
                return {
                    url: "classes/create",
                    method: "POST",
                    headers: {
                        authorization: window.localStorage.getItem("_token")
                    },
                    body: create_class_request
                }
            }
        }),

        // Find Class
        findClass: builder.mutation<FindClassResponse, Partial<FindClassRequest>>({
            // @ts-ignore
            query(create_class_request) {
                return {
                    url: "classes/find",
                    method: "GET",
                    headers: {
                        authorization: window.localStorage.getItem("_token")
                    },
                    query: create_class_request
                }
            }
        }),

        // Get a class
        getClass: builder.query({
            // @ts-ignore
            query: (get_class_request) => {
                return {
                    url: "classes/find",
                    headers: {
                        authorization: window.localStorage.getItem("_token")
                    },
                    params: get_class_request
                }
            }
        }),

        // Find Class Members
        findClassMembers: builder.query({
            // @ts-ignore
            query: (find_class_members_request) => {
                return {
                    url: "classes/members/find",
                    headers: {
                        authorization: window.localStorage.getItem("_token")
                    },
                    params: find_class_members_request
                }
            }
        }),

        // Remove a class
        removeClass: builder.mutation<RemoveClassResponse, Partial<RemoveClassRequest>>({
            // @ts-ignore
            query(remove_class_request) {
                console.log(remove_class_request)
                return {
                    url: "classes/remove",
                    method: "POST",
                    headers: {
                        authorization: window.localStorage.getItem("_token")
                    },
                    body: remove_class_request
                }
            }
        }),

        // Join Class Mutation
        joinClass: builder.mutation<JoinClassResponse, Partial<JoinClassRequest>>({
            // @ts-ignore
            query: (join_class_request) => {
                return {
                    url: "classes/join",
                    method: "POST",
                    headers: {
                        authorization: window.localStorage.getItem("_token")
                    },
                    body: join_class_request
                }
            }
        }),

        // Find Student Goals
        findGoals: builder.query({
            // @ts-ignore
            query: (find_goals_request) => {
                return {
                    url: "goals/find",
                    headers: {
                        authorization: window.localStorage.getItem("_token")
                    },
                    params: find_goals_request
                }
            }
        }),

        // Create Student Goal
        createGoal: builder.mutation<CreateGoalResponse, Partial<CreateGoalRequest>>({
            // @ts-ignore
            query: (create_goal_request) => {
                return {
                    url: "goals/add",
                    method: "POST",
                    headers: {
                        authorization: window.localStorage.getItem("_token")
                    },
                    body: create_goal_request
                }
            }
        }),

        // Update Goal
        updateGoal: builder.mutation<UpdateGoalResponse, Partial<UpdateGoalRequest>>({
            // @ts-ignore
            query: (update_goal_request) => {
                return {
                    url: "goals/update",
                    method: "POST",
                    headers: {
                        authorization: window.localStorage.getItem("_token")
                    },
                    body: update_goal_request
                }
            }
        }),

        // Create Feedback
        createFeedback: builder.mutation<CreateFeedbackResponse, Partial<CreateFeedbackRequest>>({
            // @ts-ignore
            query: (create_feedback_request) => {
                return {
                    url: "goals/feedback/add",
                    method: "POST",
                    headers: {
                        authorization: window.localStorage.getItem('_token')
                    },
                    body: create_feedback_request
                }
            }
        }),

        // Remove Goal
        removeGoal: builder.mutation<RemoveGoalResponse, Partial<RemoveGoalRequest>>({
            // @ts-ignore
            query: (remove_goal_request) => {
                return {
                    url: "goals/remove",
                    method: "POST",
                    headers: {
                        authorization: window.localStorage.getItem('_token')
                    },
                    body: remove_goal_request
                }
            }
        }),

        // Remove Member
        removeMember: builder.mutation<RemoveMemberResponse, Partial<RemoveMemberRequest>>({
            // @ts-ignore
            query: (remove_member_request) => {
                return {
                    url: "classes/members/remove",
                    method: "POST",
                    headers: {
                        authorization: window.localStorage.getItem('_token')
                    },
                    body: remove_member_request
                }
            }
        }),

        // Remove feedback
        removeFeedback: builder.mutation<RemoveFeedbackResponse, Partial<RemoveFeedbackRequest>>({
            // @ts-ignore
            query: (remove_feedback_request) => {
                return {
                    url: "goals/feedback/remove",
                    method: "POST",
                    headers: {
                        authorization: window.localStorage.getItem('_token')
                    },
                    body: remove_feedback_request
                }
            }
        }),

        // Update Image
        updateImage: builder.mutation({
            // @ts-ignore
            query: (update_image_request) => {
                return {
                    url: "user/image/add",
                    method: "POST",
                    headers: {
                        authorization: window.localStorage.getItem('_token')
                    },
                    body: update_image_request
                }
            }
        }),

        // Get Image
        getImage: builder.query({
            // @ts-ignore
            query: (get_image_request) => {
                return {
                    url: "user/image",
                    headers: {
                        authorization: window.localStorage.getItem('_token')
                    },
                    params: get_image_request
                }
            }
        }),

        // Update User
        updateUser: builder.mutation({
            // @ts-ignore
            query: (update_user_request) => {
                return {
                    url: "user/update",
                    method: "POST",
                    headers: {
                        authorization: window.localStorage.getItem('_token')
                    },
                    body: update_user_request
                }
            }
        }),

        // Find Channels
        findChannels: builder.query({
            // @ts-ignore
            query: () => {
                return {
                    url: "user/channels",
                    headers: {
                        authorization: window.localStorage.getItem("_token")
                    }
                }
            }
        }),

        // Send Message
        sendMessage: builder.mutation({
            // @ts-ignore
            query: (send_message_request) => {
                return {
                    url: "messages/send",
                    method: "POST",
                    headers: {
                        authorization: window.localStorage.getItem("_token")
                    },
                    body: send_message_request
                }
            }
        }),

        // Find Messages
        findMessages: builder.query({
            // @ts-ignore
            query: (find_message_request) => {
                return {
                    url: "messages/find",
                    method: "GET",
                    headers: {
                        authorization: window.localStorage.getItem("_token")
                    },
                    params: find_message_request
                }
            }
        }),

        // Find Messages Mut
        findMessages2: builder.mutation({
            // @ts-ignore
            query: (find_message_request) => {
                return {
                    url: "messages/find",
                    method: "GET",
                    headers: {
                        authorization: window.localStorage.getItem("_token")
                    },
                    query: find_message_request
                }
            }
        }),

        // Get Messages Activity
        findMessagesActivity: builder.query({
            // @ts-ignore
            query: (find_message_request) => {
                return {
                    url: "activity/messages",
                    method: "GET",
                    headers: {
                        authorization: window.localStorage.getItem("_token")
                    }
                }
            }
        }),

        // Get Feedback Activity
        findFeedbackActivity: builder.query({
            // @ts-ignore
            query: (find_feedback_request) => {
                return {
                    url: "activity/feedback",
                    method: "GET",
                    headers: {
                        authorization: window.localStorage.getItem("_token")
                    }
                }
            }
        })
    })
});

export const {
    useSignUpMutation,
    useSignInMutation,
    useFindMeMutation,
    useFindAllClassesQuery,
    useCreateClassMutation,
    useFindClassMutation,
    useGetClassQuery,
    useFindClassMembersQuery,
    useRemoveClassMutation,
    useJoinClassMutation,
    useFindGoalsQuery,
    useCreateGoalMutation,
    useUpdateGoalMutation,
    useLazyFindGoalsQuery,
    useCreateFeedbackMutation,
    useRemoveGoalMutation,
    useRemoveMemberMutation,
    useRemoveFeedbackMutation,
    useUpdateImageMutation,
    useGetImageQuery,
    useUpdateUserMutation,
    useFindChannelsQuery,
    useSendMessageMutation,
    useFindMessagesQuery,
    useFindMessages2Mutation,
    useFindMessagesActivityQuery,
    useFindFeedbackActivityQuery
} = api;