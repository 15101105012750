import React, { useState } from 'react';

import { RoleButton, SubmitButton } from '../../../../components/primitives/interactions';
import { HeaderIcon, RoleIcon } from '../../../../components/primitives/images';
import { FeatureTitle, FeatureDescription, RoleHeader } from '../../../../components/primitives/typography';
import { SectionContainer, SignUpFormContainer } from '../../../../components/primitives/containers';

// @ts-ignore
import Logo from '../../../../assets/logo.svg';
// @ts-ignore
import Student from '../../../../assets/img_user_66X60.svg';
// @ts-ignore
import Teacher from '../../../../assets/img_mail.svg';

interface RoleProps {
    incrementIndex: Function
    decrementIndex: Function
    setFinalRole: Function
};

const Role = ({ ...props }: RoleProps) => {
    const {
        incrementIndex,
        decrementIndex,
        setFinalRole
    } = props;

    const [teacher, setTeacher] = useState(false);
    const [student, setStudent] = useState(false);

    const chooseStudent = () => {
        if(teacher) {
            setTeacher(false);
        }

        setStudent(true);
    };

    const chooseTeacher = () => {
        if(student) {
            setStudent(false);
        }

        setTeacher(true);
    }

    const SubmitRole = () => {
        if(student || teacher) {
            setFinalRole((student) ? "student" : "instructor");
            incrementIndex();
        }
    }

    return (
        <SectionContainer style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <SignUpFormContainer>
                <HeaderIcon style={{
                    width: "3vw"
                }} src={Logo} />
                <FeatureTitle style={{
                    fontSize: "5vh"
                }}>Pick your role</FeatureTitle>
                <FeatureDescription style={{
                    fontSize: "2vh"
                }}>
                    This will dictate your interface.
                </FeatureDescription>
                
                <div style={{
                    display: "flex",
                    gap: "2vw",
                    marginTop: "5vh"
                }}>
                    <div style={{ display: "flex", gap: "1vh", flexDirection: "column", alignItems: "center" }}>
                        <RoleHeader style={{color: "gray"}}>Teacher</RoleHeader>
                        <RoleButton status={teacher} onClick={() => {
                            chooseTeacher();
                        }}>
                            <RoleIcon src={Teacher} />
                        </RoleButton>
                    </div>
                    <div style={{ display: "flex", gap: "1vh", flexDirection: "column", alignItems: "center" }}>
                        <RoleHeader style={{color: "gray"}}>Student</RoleHeader>
                        <RoleButton status={student} onClick={() => {
                            chooseStudent();
                        }}>
                            <RoleIcon src={Student} />
                        </RoleButton>
                    </div>
                </div>
                <SubmitButton onClick={() => { SubmitRole(); }}>Continue</SubmitButton>
            </SignUpFormContainer>
        </SectionContainer>
    )
};

export default Role;