import io from 'socket.io-client';

import React, { useState, useEffect } from 'react';
import AppHeader from '../../components/composite/headers/appheader';
import MessageTable from '../../components/composite/messagetable';

import AppNavigation from '../../components/nav/app';

import Drawer from '../../components/composite/drawer';
import Modal from '../../components/composite/modal';

import { ContentContainer, ContentInterior, MainContainer, PageHeaderContainer } from '../../components/primitives/containers';
import { PageHeaderDescription, PageHeaderTitle } from '../../components/primitives/typography';

const Messages = () => {
    const [drawerType, setDrawerType] = useState("");
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerData, setDrawerData] = useState(null);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalType, setModalType] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const openNotificationDrawer = (unread_feedback: Array<Object>, unread_messages: Array<Object>) => {
        // @ts-ignore
        setDrawerData({ unread_feedback, unread_messages });
        setDrawerType("notifications");
        setDrawerOpen(true);
    }

    const closeNotificationDrawer = () => {
        setDrawerData(null);
        setDrawerType("");
        setDrawerOpen(false);
    }

    const signOut = () => {
        // @ts-ignore
        setModalType("confirmation");
        // @ts-ignore
        setModalTitle("Sign Out");
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    }

    const SignOut = () => {
        window.localStorage.removeItem("_token");
        window.localStorage.removeItem("_name");
        window.localStorage.removeItem("_email");
        window.localStorage.removeItem("_role");

        window.location.reload();
        window.location.href = "/";
    };

    return (
        <MainContainer style={{
            display: "flex"
        }}>
            <AppNavigation signOut={signOut} page={"messages"} />
            <ContentContainer>
                <AppHeader openNotificationDrawer={openNotificationDrawer} />
                <ContentInterior>
                    <PageHeaderContainer>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1vh"
                        }}>
                            {/* @ts-ignore */}
                            <PageHeaderTitle>Your Messages</PageHeaderTitle>
                            <PageHeaderDescription>View messages from instructors and students.</PageHeaderDescription>
                        </div>
                    </PageHeaderContainer>

                    <MessageTable />
                </ContentInterior>
            </ContentContainer>
            <Modal
                open={modalOpen}
                type={modalType}
                closeModal={closeModal}
                // @ts-ignore
                title={modalTitle}
                signOut={SignOut}
            />
            <Drawer 
                type={drawerType}
                open={drawerOpen}
                close={closeNotificationDrawer}
                data={drawerData}
            />
        </MainContainer>
    );
};

export default Messages;