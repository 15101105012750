import React, { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import PageHeader from '../../components/composite/headers/pageheader';
import Goals from '../../components/composite/goals';
import Feedback from '../../components/composite/feedback';

import AppHeader from '../../components/composite/headers/appheader';
import AppNavigation from '../../components/nav/app';
import MemberHeader from '../../components/composite/headers/memberheader';

import Modal from '../../components/composite/modal';
import Drawer from '../../components/composite/drawer';

import { ContentContainer, ContentInterior, MainContainer } from '../../components/primitives/containers';

import { useFindGoalsQuery, useLazyFindGoalsQuery, useFindClassMembersQuery, useGetClassQuery, useRemoveGoalMutation, useRemoveFeedbackMutation } from '../../state/api';
import FourOhFourPage from '../404';

import { Oval } from 'react-loader-spinner';

const StudentView = () => {
    const [drawerType, setDrawerType] = useState("");
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerData, setDrawerData] = useState(null);
    const [modalType, setModalType] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);

    const { goal_id, class_id } = useParams();

    const { data, isLoading, isSuccess } = useGetClassQuery({
        class_id
    });

    const { data: goalData, isLoading: goalIsLoading, isSuccess: goalIsSuccess } = useFindGoalsQuery({
        class_id,
        goal_id,
        student_id: window.localStorage.getItem("_user_id")
    });

    const signOut = () => {
        // @ts-ignore
        setModalType("confirmation");
        // @ts-ignore
        setModalTitle("Sign Out");
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const SignOut = () => {
        window.localStorage.removeItem("_token");
        window.localStorage.removeItem("_name");
        window.localStorage.removeItem("_email");
        window.localStorage.removeItem("_role");

        window.location.reload();
        window.location.href = "/";
    };

    const openNotificationDrawer = (unread_feedback: Array<Object>, unread_messages: Array<Object>) => {
        // @ts-ignore
        setDrawerData({ unread_feedback, unread_messages });
        setDrawerType("notifications");
        setDrawerOpen(true);
    }

    const closeNotificationDrawer = () => {
        setDrawerData(null);
        setDrawerType("");
        setDrawerOpen(false);
    }

    return <MainContainer style={{
        display: "flex"
    }}>
        <AppNavigation signOut={signOut} page={"home"} />
        <ContentContainer>
            <AppHeader openNotificationDrawer={openNotificationDrawer} />
            {
                (goalIsLoading || isLoading) ? <ContentInterior style={{
                    position: "relative",
                    paddingTop: 0,
                    height: "100vh",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <Oval
                        height={40}
                        width={40}
                        color="#6EA8C8"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="white"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </ContentInterior> : (goalIsSuccess && isSuccess) ? <ContentInterior style={{
                    position: "relative",
                    paddingTop: 0
                }}>
                    <PageHeader
                        className={data.data[0].name}
                        classSymbol={data.data[0].symbol}
                        classColor={data.data[0].color}
                        page={"class"}
                    />

                    <div style={{
                        width: "100%",
                        display: "flex",
                        marginTop: "2vh",
                        boxSizing: "border-box",
                        gap: "3vw",
                        position: "relative"
                    }}>
                        <Goals
                            // @ts-ignore
                            class_id={class_id}
                            // @ts-ignore
                            student_id={window.localStorage.getItem("_user_id")}
                            // @ts-ignore
                            student_name={window.localStorage.getItem('_name')}
                        />
                        <Feedback
                            selected={true}
                            // @ts-ignore
                            goal_id={goal_id}
                            class_id={class_id}
                            // @ts-ignore
                            student_id={window.localStorage.getItem("_user_id")}
                            instructors={data.data[0].instructors}
                        />
                    </div>
                </ContentInterior>
                    : <></>
            }
        </ContentContainer>
        <Modal
            open={modalOpen}
            type={modalType}
            // @ts-ignore
            title={modalTitle}
            classId={class_id}
            // @ts-ignore
            studentId={window.localStorage.getItem('_user_id')}
            signOut={SignOut}
            closeModal={closeModal}
        />

        <Drawer
            type={drawerType}
            open={drawerOpen}
            close={closeNotificationDrawer}
            data={drawerData}
        />
    </MainContainer>
};

const TeacherView = () => {
    const [drawerType, setDrawerType] = useState("");
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerData, setDrawerData] = useState(null);
    const [feedbackContent, setFeedbackContent] = useState(null);
    const [progressLevel, setProgressLevel] = useState(null);
    const [modalType, setModalType] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [feedbackTimestamp, setFeedbackTimestamp] = useState(null);
    const [modalTitle, setModalTitle] = useState(null);
    const [goalSelected, setGoalSelected] = useState(false);

    const navigate = useNavigate();

    const { goal_id, class_id, student_id } = useParams();

    const { data: classMembers, isSuccess: isClassSuccess, isLoading: isClassLoading } = useFindClassMembersQuery({
        class_id: class_id
    });

    const [removeFeedbackMut] = useRemoveFeedbackMutation();

    const [removeGoalMut] = useRemoveGoalMutation();

    const { data: goals, isLoading, isSuccess } = useFindGoalsQuery({
        class_id: class_id,
        goal_id: goal_id,
        student_id: student_id
    });

    const signOut = () => {
        // @ts-ignore
        setModalType("confirmation");
        // @ts-ignore
        setModalTitle("Sign Out");
        setModalOpen(true);
    };

    const createGoal = () => {
        // @ts-ignore
        setModalType("add_goal");
        setModalOpen(true);
    };

    const SignOut = () => {
        window.localStorage.removeItem("_token");
        window.localStorage.removeItem("_name");
        window.localStorage.removeItem("_email");
        window.localStorage.removeItem("_role");

        window.location.reload();
        window.location.href = "/";
    }

    const selectGoal = (goal_id: String, goal_progress: String) => {
        // @ts-ignore
        setGoalId(goal_id);
        // @ts-ignore
        setGoalProgress(goal_progress);
        setGoalSelected(true);
    };

    const closeModal = () => {
        setFeedbackTimestamp(null);
        setModalOpen(false);
    };

    const removeGoal = () => {
        // @ts-ignore
        setModalType("confirmation");
        // @ts-ignore
        setModalTitle("Remove Goal");
        setModalOpen(true);
    };

    const RemoveGoal = async () => {
        const results = await removeGoalMut({
            class_id,
            student_id,
            goal_id
        });

        // @ts-ignore
        if (results.data.data === "success") {
            window.location.reload();
            window.location.href = "/class/" + class_id + "/student/" + student_id;
        } else {
            closeModal();
        }
    };

    const removeFeedback = (timestamp: number) => {
        // @ts-ignore
        setModalType("confirmation");
        // @ts-ignore
        setFeedbackTimestamp(timestamp)
        // @ts-ignore
        setModalTitle("Remove Feedback");
        setModalOpen(true);
    };

    const RemoveFeedback = async () => {
        const results = await removeFeedbackMut({
            class_id,
            goal_id,
            student_id,
            timestamp: feedbackTimestamp
        });

        // @ts-ignore
        if (results.data.data.error) {
            closeModal();
        } else {
            window.location.reload();
            closeModal();
        }
    };

    const openNotificationDrawer = (unread_feedback: Array<Object>, unread_messages: Array<Object>) => {
        // @ts-ignore
        setDrawerData({ unread_feedback, unread_messages });
        setDrawerType("notifications");
        setDrawerOpen(true);
    }

    const closeNotificationDrawer = () => {
        setDrawerData(null);
        setDrawerType("");
        setDrawerOpen(false);
    }

    let members = [];
    let instructors = [];
    let student = {};
    let goalProgress = "";

    if (isClassSuccess) {
        members = classMembers.data.filter((m: any) => {
            return m.user_id === student_id
        });

        instructors = classMembers.data.filter((m: any) => {
            return m.role === "instructor"
        });

        student = members[0];

        if (isSuccess) {
            goalProgress = goals.data[0].progress;
        }
    }

    return (
        <MainContainer style={{
            display: "flex"
        }}>
            <AppNavigation
                signOut={signOut} page={"home"}
            />

            <ContentContainer>
                <AppHeader openNotificationDrawer={openNotificationDrawer} />
                <ContentInterior style={{
                    position: "relative",
                    paddingTop: 0
                }}>
                    {
                        (isClassSuccess && isSuccess) ? <>
                            <MemberHeader
                                // @ts-ignore 
                                name={student.name}
                                // @ts-ignore
                                email={student.email}
                                // @ts-ignore
                                pfp={student.pfp}
                                // @ts-ignore
                                channels={student.channels}
                            />
                            <div style={{
                                width: "100%",
                                display: "flex",
                                marginTop: "2vh",
                                boxSizing: "border-box",
                                gap: "3vw",
                                position: "relative"
                            }}>
                                <Goals
                                    // @ts-ignore
                                    class_id={class_id!}
                                    // @ts-ignore
                                    student_id={student.user_id}
                                    // @ts-ignore
                                    student_name={student.name}
                                    // @ts-ignore
                                    createGoal={createGoal}
                                    // @ts-ignore
                                    selectGoal={selectGoal}
                                />

                                {/* @ts-ignore */}
                                <Feedback instructors={instructors} removeFeedback={removeFeedback} removeGoal={removeGoal} selected={true} progress={goalProgress} student_id={student.user_id} class_id={class_id} goal_id={goal_id} />
                            </div>
                        </> : (isClassLoading || isLoading) ?
                            <div style={{
                                width: "100%",
                                display: "flex",
                                marginTop: "2vh",
                                boxSizing: "border-box",
                                gap: "3vw",
                                position: "relative",
                                height: "100vh",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                                <Oval
                                    height={40}
                                    width={40}
                                    color="#6EA8C8"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                    ariaLabel='oval-loading'
                                    secondaryColor="white"
                                    strokeWidth={2}
                                    strokeWidthSecondary={2}
                                />
                            </div> : <></>
                    }
                </ContentInterior>
            </ContentContainer>
            <Modal
                open={modalOpen}
                type={modalType}
                // @ts-ignore
                title={modalTitle}
                classId={class_id}
                // @ts-ignore
                studentId={student.user_id}
                removeFeedback={RemoveFeedback}
                signOut={SignOut}
                closeModal={closeModal}
                removeGoal={RemoveGoal}
            />
            <Drawer
                type={drawerType}
                open={drawerOpen}
                close={closeNotificationDrawer}
                data={drawerData}
            />
        </MainContainer>
    );
};

const Goal = () => {
    const role = window.localStorage.getItem("_role");
    return (role === "instructor") ?
        <TeacherView /> : <StudentView />;
};

export default Goal;