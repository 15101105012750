import React from 'react';

import { FaPlus, FaTrash } from 'react-icons/fa';

import { HeaderContainer, PageHeaderContainer } from '../../primitives/containers';
import { PageHeaderButton, RemoveClassButton } from '../../primitives/interactions';
import { PageHeaderDescription, PageHeaderTitle } from '../../primitives/typography';

interface PageHeaderProps {
    page: String,
    addClass?: Function
    className?: String
    classSymbol?: String
    classColor?: String
    instructorCode?: String
    studentCode?: String
    removeClass?: Function
    addMember?: Function
    joinClass?: Function
};

const PageHeader = ({ ...props }: PageHeaderProps) => {
    const {
        page,
        addClass
    } = props;

    const role = window.localStorage.getItem('_role');

    return (
        <PageHeaderContainer style={(role === "student" && page === "class") ? {
            position: "fixed",
            width: "70vw",
            backgroundColor: "white",
            paddingTop: "20vh",
            zIndex: 1
        } : {
            display: "flex"
        }}>
            {(role === "instructor") ? <div style={{
                display: "flex",
                flexDirection: "column",
                gap: "1vh"
            }}>
                {/* @ts-ignore */}
                <PageHeaderTitle>{(page === "home") ? "Home" : (page === "class") ? <><span style={{ color: props.classColor, fontWeight: "bold" }}>{props.classSymbol}</span><span>{" / " + props.className}</span></> : ""}</PageHeaderTitle>
                <PageHeaderDescription>{(page === "home") ? "Overview of your projects and activities" : (page === "class") ? "Overview of your class members" : ""}</PageHeaderDescription>
            </div> : <></>}

            {(role === "student") ? <div style={{
                display: "flex",
                flexDirection: "column",
                gap: "1vh"
            }}>
                {/* @ts-ignore */}
                <PageHeaderTitle>{(page === "home") ? "Home" : (page === "class") ? <><span style={{ color: props.classColor, fontWeight: "bold" }}>{props.classSymbol}</span><span>{" / " + props.className}</span></> : ""}</PageHeaderTitle>
                <PageHeaderDescription>{(page === "home") ? "Overview of your projects and activities" : (page === "class") ? "Overview of your goals and progress" : ""}</PageHeaderDescription>
            </div> : <></>}

            {(page === "home") ?
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1vw"
                }}>
                    <PageHeaderButton style={{ color: "#6EA8C8" }} onClick={() => {
                        // @ts-ignore
                        props.joinClass();
                    }} status={true}><FaPlus size={15} />Join Class</PageHeaderButton>
                    {(role === "instructor") ? <PageHeaderButton style={{ color: "#6EA8C8" }} onClick={() => {
                        // @ts-ignore
                        addClass();
                    }} status={true}><FaPlus size={15} />Create Class</PageHeaderButton> : <></>}

                </div>
                : <></>}

            {(role === "instructor") ?
                (page === "class") ? <div style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1vw"
                }}>
                    <PageHeaderButton style={{ color: "#6EA8C8" }} onClick={() => {
                        // @ts-ignore
                        props.addMember();
                    }} status={true}><FaPlus size={15} />Add Member</PageHeaderButton>

                    <RemoveClassButton onClick={() => {
                        // @ts-ignore
                        props.removeClass();
                    }}>
                        <FaTrash />
                        Remove Class
                    </RemoveClassButton>
                </div> : <></> : <></>}
        </PageHeaderContainer>
    );
};

export default PageHeader;