import React from 'react';

import { useParams } from 'react-router-dom';

import { FaPlus } from 'react-icons/fa';
import { BiMessageAltDetail } from 'react-icons/bi';

import { useFindGoalsQuery } from '../../state/api';

import { PageHeaderButton } from '../primitives/interactions';
import { GoalsContainer, GoalsInterior } from '../primitives/containers';
import { GenericText } from '../primitives/typography';
import GoalCard from './cards/goal';

import { Oval } from 'react-loader-spinner';

interface GoalsProps {
    class_id: String
    student_id: String
    student_name: String
    createGoal: Function
    selectGoal: Function
};

const Goals = ({ ...props }: GoalsProps) => {
    const { class_id } = useParams();

    const { data, isLoading, isSuccess } = useFindGoalsQuery({
        student_id: props.student_id,
        class_id: class_id
    });

    let GoalComponents = [];

    if (isSuccess) {
        GoalComponents = data.data.map((goal: any, i: number) => {
            return <GoalCard
                goal_id={goal.goal_id}
                title={goal.title}
                color={goal.color}
                selectGoal={props.selectGoal}
                progress={goal.progress}
                class_id={goal.class_id}
                student_id={props.student_id}
                key={i}
            />
        });
    }

    return <GoalsContainer>
        <div style={{
            display: "flex",
            width: "100%",
            alignItems: "center"
        }}>
            <div style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between"
            }}>
                {/* @ts-ignore */}
                {(window.localStorage.getItem("_role") === "instructor") ?
                    <GenericText style={{
                        fontWeight: "bold",
                        fontSize: "2vh"
                    }}>{props.student_name.split(" ")[0] + "'s Goals"}</GenericText>
                    : <GenericText style={{
                        fontWeight: "bold",
                        fontSize: "2vh"
                    }}>{"My Goals"}</GenericText>}
            </div>

            {(window.localStorage.getItem("_role") === "instructor") ?
                <PageHeaderButton onClick={() => {
                    props.createGoal();
                }} style={{
                    color: "#6EA8C8"
                }}>
                    <FaPlus />
                    Create
                </PageHeaderButton>
                : <></>}
        </div>

        {
            (isSuccess) ?
                ((data.data.length === 0) ?
                    <GoalsInterior style={{
                        alignItems: "center",
                        justifyContent: "center",
                        height: "52vh"
                    }}>
                        <GenericText style={{ color: "gray" }}>No goals created yet.</GenericText>
                    </GoalsInterior>
                    : <GoalsInterior style={{
                        backgroundColor: "transparent",
                        display: "flex",
                        gap: "1vw",
                        overflowY: "scroll",
                        height: "51vh"
                    }}>
                        {GoalComponents}
                    </GoalsInterior>)
                : (isLoading) ?
                    <div style={{
                        backgroundColor: "transparent",
                        display: "flex",
                        gap: "1vw",
                        overflowY: "scroll",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                        height: "52vh"
                    }}>
                        <Oval
                            height={40}
                            width={40}
                            color="#6EA8C8"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="white"
                            strokeWidth={2}
                            strokeWidthSecondary={2}
                        />
                    </div>
                    : <></>

        }
    </GoalsContainer>
};

export default Goals;