import React from 'react';

import ReactModal from 'react-modal';

import AddClass from './views/addclass';
import AddGoal from './views/addgoal';
import AddMember from './views/addmember';
import Confirmation from './views/confirmation';
import JoinClass from './views/joinclass';

interface ModalProps {
    open: boolean
    type: String | null
    closeModal: Function
    title?: String
    signOut?: Function
    joinClass?: Function
    joinCode?: String
    classId?: String
    studentId?: String
    removeMember?: Function
    removeFeedback?: Function
}

const Modal = ({ ...props }: ModalProps) => {
    return (
        <ReactModal
            isOpen={props.open}
            style={{
                content: {
                    backgroundColor: "transparent",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "none"
                },
                overlay: {
                    zIndex: 3
                }
            }}
        >
            {/* @ts-ignore */}
            {(props.type === "confirmation") ? <Confirmation removeFeedback={props.removeFeedback} removeMember={props.removeMember} removeGoal={props.removeGoal} signOut={props.signOut} title={props.title} closeModal={props.closeModal} /> : <></>}
            {(props.type === "add_class") ? <AddClass closeModal={props.closeModal} /> : <></>}
            {/* @ts-ignore */}
            {(props.type === "add_member") ? <AddMember classId={props.classId} joinCode={props.joinCode} closeModal={props.closeModal} /> : <></>}
            {/* @ts-ignore */}
            {(props.type === "join_class") ? <JoinClass joinClass={props.joinClass} closeModal={props.closeModal} /> : <></>}
            {/* @ts-ignore */}
            {(props.type === "add_goal") ? <AddGoal class_id={props.classId} student_id={props.studentId} closeModal={props.closeModal} /> : <></>}
        </ReactModal>
    )
};

export default Modal;