import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PageHeaderButton } from '../../primitives/interactions';

import { GenericText } from '../../primitives/typography';
import { S3_URL } from '../../../constants';

interface ChatHeaderProps {
    pfp?: String
    name?: String
    email?: String
    class_id?: String
    user_id?: String
};

const ChatHeader = ({ ...props}: ChatHeaderProps) => {
    const navigate = useNavigate();

    return (
        <div style={{
            width: "100%",
            paddingTop: "3vh",
            paddingBottom: "3vh",
            backgroundColor: "white",
            paddingLeft: "4vw",
            paddingRight: "6vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            boxSizing: "border-box"
        }}>
            <div style={{
                display: "flex",
                alignItems: "center",
                gap: "1vw"
            }}>
                {/* @ts-ignore */}
                <img style={{
                    width: "4vw",
                    height: "4vw",
                    borderRadius: "100vh"
                }} src={S3_URL + props.pfp} />
                <div style={{
                    display: "flex",
                    flexDirection: "column"
                }}>
                    <GenericText>{props.name}</GenericText>
                    <GenericText style={{color: "gray"}}>{props.email}</GenericText>
                </div>
            </div>
            <PageHeaderButton onClick={() => {
                (window.localStorage.getItem('_role') === "instructor") ? navigate("/class/" + props.class_id + "/student/" + props.user_id) : navigate("/class/" + props.class_id);
            }} style={{
                    color: "#6EA8C8",
                    padding: 0
                }}>
                    {/* @ts-ignore */}
                    {(window.localStorage.getItem("_role") === "instructor") ? "View " + props.name.split(" ")[0] + "'s Profile" : "View My Profile"}
                </PageHeaderButton>
        </div>
    )
};

export default ChatHeader;