import React, { useState } from 'react';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import { MainContainer } from '../../../components/primitives/containers';

import {
    useSignUpMutation,
    useFindMeMutation,
    useUpdateImageMutation
} from '../../../state/api';

import Email from './pages/email';
import Name from './pages/name';
import Password from './pages/password';
import Role from './pages/role';
import Success from './pages/success';
import Image from './pages/image';

import { Oval } from 'react-loader-spinner';

const Registration = () => {
    const [index, setIndex] = useState(0);
    const [email, setFinalEmail] = useState(null);
    const [name, setFinalName] = useState(null);
    const [finalImage, setFinalImage] = useState(null);
    const [password, setFinalPassword] = useState(null);
    const [role, setFinalRole] = useState(null);

    const [signUp, { data, isLoading }] = useSignUpMutation();
    const [updateImage] = useUpdateImageMutation();
    const [findMe] = useFindMeMutation();

    const incrementIndex = () => {
        setIndex(index + 1);
    }

    const decrementIndex = () => {
        setIndex(index - 1);
    }

    const SetFinalPassword2 = async (val: any) => {
        const signup_request = {
            email,
            name,
            role,
            password: val
        };

        const uploadData = new FormData();

        // @ts-ignore
        uploadData.append('file', finalImage);
        // @ts-ignore
        uploadData.append('email', email);
        // @ts-ignore
        uploadData.append('name', name);
        // @ts-ignore
        uploadData.append('role', role);
        // @ts-ignore
        uploadData.append('password', val);

        // @ts-ignore
        const signup_response = await signUp(uploadData);

        // @ts-ignore
        if (signup_response.error === null || signup_response.error === undefined) {
            const user = await findMe({
                // @ts-ignore
                authorization: signup_response.data.authorization
            });

            // @ts-ignore
            window.localStorage.setItem("_pfp", user.data.data.pfp);
            // @ts-ignore
            window.localStorage.setItem("_name", user.data.data.name);
            // @ts-ignore
            window.localStorage.setItem("_email", user.data.data.email);
            // @ts-ignore
            window.localStorage.setItem("_role", user.data.data.role);
            // @ts-ignore
            window.localStorage.setItem("_token", signup_response.data.authorization);
        }
    }

    return (
        <MainContainer>
            {
                (isLoading) ? <div>
                    <Oval
                        height={40}
                        width={40}
                        color="#6EA8C8"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="white"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </div> : <Carousel
                    selectedItem={index}
                    showStatus={false}
                    showArrows={false}
                    showIndicators={false}
                    showThumbs={false}
                    axis={"vertical"}
                >
                    <Email
                        incrementIndex={incrementIndex}
                        decrementIndex={decrementIndex}
                        setFinalEmail={setFinalEmail}
                    />
                    <Name
                        incrementIndex={incrementIndex}
                        decrementIndex={decrementIndex}
                        setFinalName={setFinalName}
                    />
                    <Image
                        incrementIndex={incrementIndex}
                        decrementIndex={decrementIndex}
                        setFinalImage={setFinalImage}
                    />
                    <Role
                        incrementIndex={incrementIndex}
                        decrementIndex={decrementIndex}
                        setFinalRole={setFinalRole}
                    />
                    <Password
                        incrementIndex={incrementIndex}
                        decrementIndex={decrementIndex}
                        setFinalPassword={SetFinalPassword2}
                    />
                    <Success />
                </Carousel>
            }
        </MainContainer>
    );
};

export default Registration;