import React, { useState } from 'react';
import Activity from '../../components/composite/activity';
import Classes from '../../components/composite/classes';

import { IoMdSchool } from 'react-icons/io';

import AppHeader from '../../components/composite/headers/appheader';
import PageHeader from '../../components/composite/headers/pageheader';
import Modal from '../../components/composite/modal';
import AppNavigation from '../../components/nav/app';

import { MainContainer, ContentContainer, ContentInterior } from '../../components/primitives/containers';
import Drawer from '../../components/composite/drawer';

const TeacherView = () => {
    const [drawerType, setDrawerType] = useState("");
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerData, setDrawerData] = useState(null);
    const [modalType, setModalType] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [classMode, setClassMode] = useState(null);

    const addClass = () => {
        // @ts-ignore
        setModalType("add_class");
        setModalOpen(true);
    };

    const signOut = () => {
        // @ts-ignore
        setModalType("confirmation");
        // @ts-ignore
        setModalTitle("Sign Out");
        setModalOpen(true);
    };

    const closeModal = () => {
        setClassMode(null);
        setModalOpen(false);
    }

    const SignOut = () => {
        window.localStorage.removeItem("_token");
        window.localStorage.removeItem("_name");
        window.localStorage.removeItem("_email");
        window.localStorage.removeItem("_role");

        window.location.reload();
        window.location.href = "/";
    };

    const joinClass = () => {
        // @ts-ignore
        setModalType("join_class");
        setModalOpen(true);
    };

    const JoinClass = () => {

    };

    const openNotificationDrawer = (unread_feedback: Array<Object>, unread_messages: Array<Object>) => {
        // @ts-ignore
        setDrawerData({ unread_feedback, unread_messages });
        setDrawerType("notifications");
        setDrawerOpen(true);
    }

    const closeNotificationDrawer = () => {
        setDrawerData(null);
        setDrawerType("");
        setDrawerOpen(false);
    }

    return (
        <MainContainer style={{
            display: "flex"
        }}>
            <AppNavigation signOut={signOut} page={"home"} />
            <ContentContainer>
                <AppHeader openNotificationDrawer={openNotificationDrawer} />

                <ContentInterior>
                    <PageHeader
                        joinClass={joinClass}
                        addClass={addClass}
                        page={"home"}
                    />

                    <Classes mode={classMode} />
                </ContentInterior>
            </ContentContainer>

            <Modal
                open={modalOpen}
                type={modalType}
                closeModal={closeModal}
                // @ts-ignore
                title={modalTitle}
                signOut={SignOut}
                joinClass={JoinClass}
            />
            <Drawer 
                type={drawerType}
                open={drawerOpen}
                close={closeNotificationDrawer}
                data={drawerData}
            />
        </MainContainer>
    );
};

const StudentView = () => {
    const [drawerType, setDrawerType] = useState("");
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerData, setDrawerData] = useState(null);
    const [modalType, setModalType] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [classMode, setClassMode] = useState(null);

    const signOut = () => {
        // @ts-ignore
        setModalType("confirmation");
        // @ts-ignore
        setModalTitle("Sign Out");
        setModalOpen(true);
    };

    const closeModal = () => {
        setClassMode(null);
        setModalOpen(false);
    };

    const joinClass = () => {
        // @ts-ignore
        setModalType("join_class");
        setModalOpen(true);
    };

    const SignOut = () => {
        window.localStorage.removeItem("_token");
        window.localStorage.removeItem("_name");
        window.localStorage.removeItem("_email");
        window.localStorage.removeItem("_role");

        window.location.reload();
        window.location.href = "/";
    };

    const openNotificationDrawer = (unread_feedback: Array<Object>, unread_messages: Array<Object>) => {
        // @ts-ignore
        setDrawerData({ unread_feedback, unread_messages });
        setDrawerType("notifications");
        setDrawerOpen(true);
    }

    const closeNotificationDrawer = () => {
        setDrawerData(null);
        setDrawerType("");
        setDrawerOpen(false);
    }

    return (
        <MainContainer style={{
            display: "flex"
        }}>
            <AppNavigation signOut={signOut} page={"home"} />
            <ContentContainer>
                <AppHeader openNotificationDrawer={openNotificationDrawer} />

                <ContentInterior>
                    <PageHeader
                        page={"home"}
                        joinClass={joinClass}
                    />

                    <Classes mode={classMode} />


                </ContentInterior>
            </ContentContainer>

            <Modal
                open={modalOpen}
                type={modalType}
                closeModal={closeModal}
                joinClass={joinClass}
                // @ts-ignore
                title={modalTitle}
                signOut={SignOut}
            />
            <Drawer 
                type={drawerType}
                open={drawerOpen}
                close={closeNotificationDrawer}
                data={drawerData}
            />
        </MainContainer>
    );
};

const Home = () => {
    const role = window.localStorage.getItem("_role");

    return (role === "instructor") ? <TeacherView /> : <StudentView />
};

export default Home;