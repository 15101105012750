import React, { useEffect, useState } from 'react';

import io from 'socket.io-client';

import ChatInput from './input';

import { useFindMessagesQuery } from '../../../state/api';
import { ChatWindowContainer } from '../../primitives/containers';
import { useParams } from 'react-router-dom';
import { GenericText } from '../../primitives/typography';
import { MyMessage, OtherMessage } from './message';
import { BiLeftArrow } from 'react-icons/bi';
import { WS_API_URL } from '../../../constants';

import { Oval } from 'react-loader-spinner';

interface ChatWindowProps {
    channel_id: String
    other_member: Object
};

interface ChatMessagesProps {
    socket: any
    initialMessages: Array<Object>
}

const ChatMessages = ({ ...props }: ChatMessagesProps) => {
    const { socket } = props;

    const [messages, setMessages] = useState(props.initialMessages);
    const [typing, setTyping] = useState(false);

    const { channel_id } = useParams();

    useEffect(() => {
        if (socket) {
            socket.on("message_sent:channel_id:" + channel_id, (data: any) => {
                if (data.author.user_id !== window.localStorage.getItem("_user_id")) {
                    socket.emit("message_received", {
                        channel_id,
                        message_id: data.message_id
                    });
                }
                // @ts-ignore
                setMessages((prev) => {
                    const new_messages = [...prev];
                    new_messages.push(data);
                    return new_messages;
                });
            });

            socket.on("user_typing:channel_id:" + channel_id, (data: any) => {
                console.log(data);
            });

            return () => {
                socket.off("message_sent:channel_id:" + channel_id)
            }
        } else {
            console.log('asd');
        }
    }, [socket]);

    const { data, isSuccess } = useFindMessagesQuery({
        channel_id
    });

    if (isSuccess) {
        if (messages.length > 0) {
            let current_messages = [...messages].reverse();
            const MessageComponents = current_messages.map((m: any, i: number) => {
                if (m.author.user_id === window.localStorage.getItem('_user_id')) {
                    return <MyMessage
                        content={m.content}
                        name={m.author.name}
                        email={m.author.email}
                        user_id={m.author.user_id}
                        pfp={m.author.pfp}
                        timestamp={m.timestamp}
                        key={i}
                    />
                } else {
                    if (m.viewed === false) {
                        socket.emit("message_received", {
                            channel_id,
                            message_id: m.message_id
                        });
                    }
                    return <OtherMessage
                        content={m.content}
                        name={m.author.name}
                        email={m.author.email}
                        user_id={m.author.user_id}
                        pfp={m.author.pfp}
                        timestamp={m.timestamp}
                        key={i}
                    />
                }
            });

            return (
                <div style={{
                    height: "80%",
                    width: "100%",
                    paddingRight: "5%",
                    paddingLeft: "5%",
                    margin: "0 auto",
                    overflowY: "scroll",
                    overflowX: "hidden",
                    display: "flex",
                    alignItems: "center",
                    boxSizing: "border-box",
                    flexDirection: "column-reverse",
                    gap: "2vh",
                    paddingTop: "2vh",
                    position: "relative"
                }}>
                    {MessageComponents}
                    {(typing) ? <img style={{
                        position: "absolute",
                        left: "5%",
                        width: "5vw",
                        bottom: "-3vh"
                    }} src={"https://media.tenor.com/53JWSqJt16QAAAAM/waiting-texting.gif"} /> : <></>}
                </div>
            )
        } else {
            return (
                <div style={{
                    width: "90%",
                    height: "100%",
                    margin: "0 auto",
                    overflowX: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    boxSizing: "border-box"
                }}>
                    <GenericText style={{ color: "gray" }}>No Messages.</GenericText>
                </div>
            )
        }
    } else {
        return <></>
    }
};

const Window = ({ ...props }: ChatWindowProps) => {
    const [socket, setSocket] = useState(null);
    const { channel_id } = useParams();

    // @ts-ignore
    useEffect(() => {
        const new_socket = io(WS_API_URL, {
            extraHeaders: {
                // @ts-ignore
                authorization: window.localStorage.getItem("_token"),
                "ngrok-skip-browser-warning": "any"
            }
        });

        // @ts-ignore
        setSocket(new_socket);
        return () => new_socket.close();
    }, [setSocket]);

    const { data, isSuccess, isLoading } = useFindMessagesQuery({
        channel_id
    });

    return (
        <ChatWindowContainer>
            {(isSuccess) ? <>
                <ChatMessages initialMessages={data.data} socket={socket} />
            </> :
                (isLoading) ? <div style={{
                    height: "80%",
                    width: "100%",
                    paddingRight: "5%",
                    paddingLeft: "5%",
                    margin: "0 auto",
                    overflowX: "hidden",
                    display: "flex",
                    alignItems: "center",
                    boxSizing: "border-box",
                    justifyContent: "center",
                    gap: "2vh",
                    paddingTop: "2vh",
                    position: "relative"
                }}>
                    <Oval
                        height={40}
                        width={40}
                        color="#6EA8C8"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="white"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </div> :
                    <></>
            }
            <ChatInput socket={socket} />
        </ChatWindowContainer>
    )
};

export default Window;