import React from 'react';
import { BadgeContainer } from '../primitives/containers';

interface BadgeProps {
    content: String
    style?: any
}

const Badge = ({ ...props }: BadgeProps) => {
    return (
        <BadgeContainer style={props.style}>
            {props.content}
        </BadgeContainer>
    )
};

export default Badge;