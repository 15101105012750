import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import AppHeader from '../../components/composite/headers/appheader';
import MemberHeader from '../../components/composite/headers/memberheader';
import Modal from '../../components/composite/modal';
import AppNavigation from '../../components/nav/app';
import Goals from '../../components/composite/goals';

import Drawer from '../../components/composite/drawer';

import { ContentContainer, ContentInterior, MainContainer } from '../../components/primitives/containers';
import { useFindClassMembersQuery } from '../../state/api';
import Feedback from '../../components/composite/feedback';

import { Oval } from 'react-loader-spinner';

interface ComponentProps {
    class_id: String
    student_id: String
};

const StudentView = ({ ...props }: ComponentProps) => {
    return (
        <></>
    )
};

const TeacherView = ({ ...props }: ComponentProps) => {
    const [drawerType, setDrawerType] = useState("");
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerData, setDrawerData] = useState(null);
    const [modalType, setModalType] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [classMode, setClassMode] = useState(null);
    const [goalId, setGoalId] = useState(null);
    const [goalSelected, setGoalSelected] = useState(false);
    const [goalProgress, setGoalProgress] = useState(false);

    const { data: classMembers, isSuccess, isLoading } = useFindClassMembersQuery({
        class_id: props.class_id
    });

    const signOut = () => {
        // @ts-ignore
        setModalType("confirmation");
        // @ts-ignore
        setModalTitle("Sign Out");
        setModalOpen(true);
    };

    const closeModal = () => {
        setClassMode(null);
        setModalOpen(false);
    };

    const SignOut = () => {
        window.localStorage.removeItem("_token");
        window.localStorage.removeItem("_name");
        window.localStorage.removeItem("_email");
        window.localStorage.removeItem("_role");

        window.location.reload();
        window.location.href = "/";
    };

    const createGoal = () => {
        // @ts-ignore
        setModalType("add_goal");
        setModalOpen(true);
    };

    const selectGoal = (goal_id: String, goal_progress: String) => {
        // @ts-ignore
        setGoalId(goal_id);
        // @ts-ignore
        setGoalProgress(goal_progress);
        setGoalSelected(true);
    };

    const openNotificationDrawer = (unread_feedback: Array<Object>, unread_messages: Array<Object>) => {
        // @ts-ignore
        setDrawerData({ unread_feedback, unread_messages });
        setDrawerType("notifications");
        setDrawerOpen(true);
    }

    const closeNotificationDrawer = () => {
        setDrawerData(null);
        setDrawerType("");
        setDrawerOpen(false);
    }

    let student = {};
    let members = [{}];

    if (isSuccess) {
        members = classMembers.data.filter((m: any) => {
            return m.user_id === props.student_id
        });

        if (members.length === 0) {
            window.location.href = "../"
        } else {
            student = members[0];
        }
    }

    return (
        <MainContainer style={{
            display: "flex"
        }}>
            <AppNavigation signOut={signOut} page={"home"} />
            <ContentContainer>
                <AppHeader openNotificationDrawer={openNotificationDrawer} />
                {
                    (isSuccess) ? <ContentInterior style={{
                        position: "relative",
                        paddingTop: 0
                    }}>
                        <MemberHeader
                            // @ts-ignore
                            name={student.name}
                            // @ts-ignore
                            email={student.email}
                            // @ts-ignore
                            pfp={student.pfp}
                            // @ts-ignore
                            channels={student.channels}
                        />

                        <div style={{
                            width: "100%",
                            display: "flex",
                            marginTop: "2vh",
                            boxSizing: "border-box",
                            gap: "3vw",
                            position: "relative"
                        }}>
                            <Goals
                                class_id={props.class_id}
                                // @ts-ignore
                                student_id={student.user_id}
                                // @ts-ignore
                                student_name={student.name}
                                createGoal={createGoal}
                                selectGoal={selectGoal}
                            />

                            {/* @ts-ignore */}
                            <Feedback instructors={members.instructors} progress={goalProgress} student_id={student.user_id} class_id={props.class_id} selected={goalSelected} goal_id={goalId} />
                        </div>
                    </ContentInterior> : (isLoading) ? <ContentInterior style={{
                        width: "100%",
                        height: "100vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <Oval
                            height={40}
                            width={40}
                            color="#6EA8C8"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="white"
                            strokeWidth={2}
                            strokeWidthSecondary={2}
                        />
                    </ContentInterior> : <></>
                }
            </ContentContainer>
            <Modal
                open={modalOpen}
                type={modalType}
                // @ts-ignore
                title={modalTitle}
                classId={props.class_id}
                // @ts-ignore
                studentId={student.user_id}
                signOut={signOut}
                closeModal={closeModal}
            />
            <Drawer
                type={drawerType}
                open={drawerOpen}
                close={closeNotificationDrawer}
                data={drawerData}
            />
        </MainContainer>
    )
};

const Student = () => {
    const role = window.localStorage.getItem("_role");

    const params = useParams();

    // @ts-ignore
    return (role === "instructor") ? <TeacherView class_id={params.class_id} student_id={params.student_id} /> : <StudentView class_id={params.class_id} student_id={params.student_id} />;
};

export default Student;