import React from 'react';
import { useNavigate } from 'react-router-dom';

import { FaTrashAlt, FaBook } from 'react-icons/fa';
import { MdGrade } from 'react-icons/md';
import { CgTrack } from 'react-icons/cg';

// @ts-ignore
import GoalIcon from '../../../assets/directions.svg';

import { GoalCardContainer } from '../../primitives/containers';
import { GenericText } from '../../primitives/typography';

interface GoalCardProps {
    goal_id: String
    title: String
    color: String
    progress: String
    class_id: String
    student_id: String
    selectGoal: Function
    key?: number
};

const StudentView = ({ ...props }: GoalCardProps) => {
    const navigate = useNavigate();

    return <GoalCardContainer onClick={() => {
        // @ts-ignore
        navigate("/class/" + props.class_id + "/goal/" + props.goal_id);
        // props.selectGoal(props.goal_id, props.progress);
        // @ts-ignore
    }} color={props.color}>
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "1vh"
        }}>
            <img src={GoalIcon} style={{width: "3vh", height: "3vh"}} />
            <GenericText style={{ fontSize: "1.5vh", color: "white" }}>{(props.title.length < 15) ? props.title : props.title.slice(0, 5) + "..." + props.title.slice(props.title.length - 5, props.title.length - 1)}</GenericText>
        </div>
        <div style={{
            position: "absolute",
            padding: "0.5vh",
            backgroundColor: "rgb(255, 255, 255, 0.5)",
            top: "1vh",
            right: "0.5vw",
            borderRadius: "1vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            {(props.progress === "exceeding_expectations") ? <MdGrade color={"#0ad48b"} /> :
                (props.progress === "behind") ? <FaBook size={15} color={"#F9C11D"} /> :
                (props.progress === "on_track") ? <CgTrack color={"#6EA8C8"} /> : <></>}
        </div>
    </GoalCardContainer>;
};

const TeacherView = ({ ...props }: GoalCardProps) => {
    const navigate = useNavigate();

    // @ts-ignore
    return <GoalCardContainer onClick={() => {
        // @ts-ignore
        navigate("/class/" + props.class_id + "/student/" + props.student_id + "/goal/" + props.goal_id);
        // props.selectGoal(props.goal_id, props.progress);
        // @ts-ignore
    }} color={props.color}>
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "1vh",
            position: "relative",
        }}>
            {/* <span style={{ fontSize: "4vh" }}><IoMdMap color='rgb(0, 0, 0, 0.25)' /></span> */}
            <img src={GoalIcon} style={{ width: "3vh", height: "3vh" }} />
            <GenericText style={{ fontSize: "1.5vh", color: "white" }}>{(props.title.length < 15) ? props.title : props.title.slice(0, 5) + "..." + props.title.slice(props.title.length - 5, props.title.length - 1)}</GenericText>
        </div>
        <div style={{
            position: "absolute",
            padding: "0.5vh",
            backgroundColor: "rgb(255, 255, 255, 0.5)",
            top: "1vh",
            right: "0.5vw",
            borderRadius: "1vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            {(props.progress === "exceeding_expectations") ? <MdGrade color={"#0ad48b"} /> :
                (props.progress === "behind") ? <FaBook size={15} color={"#F9C11D"} /> :
                (props.progress === "on_track") ? <CgTrack color={"#6EA8C8"} /> : <></>}
        </div>
    </GoalCardContainer>;
};

const GoalCard = ({ ...props }: GoalCardProps) => {
    const role = window.localStorage.getItem("_role");

    return (role === "instructor") ?
        <TeacherView key={props.key} student_id={props.student_id} class_id={props.class_id} progress={props.progress} selectGoal={props.selectGoal} goal_id={props.goal_id} title={props.title} color={props.color} />
        : <StudentView key={props.key} student_id={props.student_id} class_id={props.class_id} progress={props.progress} selectGoal={props.selectGoal} goal_id={props.goal_id} title={props.title} color={props.color} />;
};

export default GoalCard;