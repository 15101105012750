import React, { useRef, useState } from 'react';

// @ts-ignore
import Logo from '../../../../assets/logo.svg';

import { HeaderIcon } from '../../../../components/primitives/images';
import { FeatureDescription, FeatureTitle } from '../../../../components/primitives/typography';
import { ProfileButton, SectionContainer, SignUpFormContainer } from '../../../../components/primitives/containers';
import { SubmitButton } from '../../../../components/primitives/interactions';

// @ts-ignore
import DefaultPFP from '../../../../assets/default_pfp.jpg';

interface ImageProps {
    incrementIndex: Function
    decrementIndex: Function
    setFinalImage?: Function
};

const Image = ({ ...props }: ImageProps) => {
    const [file, setFile] = useState(null);
    const [image, setImage] = useState(null);

    const fileInputRef = useRef();

    const submitImage = () => {
        if (file !== null) {
            // @ts-ignore
            props.setFinalImage(file);
        }
        props.incrementIndex();
    }

    const onChange = (e: any) => {
        const fileUploaded = e.target.files[0];
        let reader = new FileReader();

        // @ts-ignore
        setFile(fileUploaded);
        // @ts-ignore
        setImage(URL.createObjectURL(e.target.files[0]));
    }

    const openFile = () => {
        // @ts-ignore
        fileInputRef.current.click();
    }

    return <SectionContainer style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }}>
        <SignUpFormContainer>
            <HeaderIcon style={{
                width: "3vw"
            }} src={Logo} />
            <FeatureTitle style={{
                fontSize: "5vh"
            }}>Pick a Profile Picture</FeatureTitle>
            <FeatureDescription style={{
                fontSize: "2vh"
            }}>
                Make sure you use something appropriate.
            </FeatureDescription>
            <ProfileButton onClick={() => {
                openFile();
            }}>
                {(!image) ? <img style={{
                    width: "15vw",
                    height: "15vw",
                    borderRadius: "100vh"
                }} src={DefaultPFP} /> : <img style={{
                    width: "15vw",
                    height: "15vw",
                    borderRadius: "100vh"
                }} src={image} />}
            </ProfileButton>
            {/* @ts-ignore */}
            <input onChange={(e) => { onChange(e); }} ref={fileInputRef} style={{ display: "none" }} type={"file"} />
            <SubmitButton onClick={() => {
                submitImage();
            }}>Continue</SubmitButton>
        </SignUpFormContainer>
    </SectionContainer >
};

export default Image;