import React, { useEffect, useRef, useState } from 'react';
import { FaTrashAlt, FaBook } from 'react-icons/fa';
import { IoMdMap } from 'react-icons/io';
import { MdGrade } from 'react-icons/md';
import { CgTrack } from 'react-icons/cg';

import io from 'socket.io-client';

import { useFindGoalsQuery, useLazyFindGoalsQuery, useCreateFeedbackMutation } from '../../state/api';

import { FeedbackContainer, GivenFeedbackContainer, GoalBadgeContainer } from '../primitives/containers';
import { FeedbackInput, MemberOptionButton, ProgressButton, SubmitButton } from '../primitives/interactions';
import { GenericText } from '../primitives/typography';

// @ts-ignore
import DefaultPFP from '../../assets/default_pfp.jpg';

// @ts-ignore
import GoalIcon from '../../assets/directions.svg';

import { useUpdateGoalMutation } from '../../state/api';
import { S3_URL, WS_API_URL } from '../../constants';

interface FeedbackProps {
    color?: String
    selected?: Boolean
    goal_id?: String
    class_id?: String
    student_id?: String
    progress?: String
    removeGoal?: Function
    removeFeedback?: Function,
    instructors?: Array<any>
};

interface GivenFeedbackProps {
    timestamp: Number
    viewed: Boolean
    instructor_id: String
    content: String
    removeFeedback: Function
    instructors?: Array<any>
    key?: number
}

const GivenFeedback = ({ ...props }: GivenFeedbackProps) => {
    const date = new Date(0);
    // @ts-ignore
    date.setUTCSeconds(props.timestamp / 1000);

    const dayOfWeek = (date.getDay() === 0) ? "Sunday" : "";

    const time = () => {
        let hours;
        let pm = false;
        if (date.getHours() > 12) {
            hours = date.getHours() - 12;
            pm = true;
        } else {
            hours = date.getHours();
        }

        return hours + ":" + ((date.getMinutes() < 10) ? "0" + date.getMinutes() : date.getMinutes()) + ((pm) ? "pm" : "am");
    }

    const this_instructor = props.instructors?.filter((i: any) => {
        return i.user_id === props.instructor_id;
    });

    return (
        <GivenFeedbackContainer>
            <div style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
            }}>
                <div style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    gap: "1vw"
                }}>
                    <img style={{
                        width: "3vw",
                        height: "3vw",
                        borderRadius: "100vh"
                        // @ts-ignore
                    }} src={S3_URL + this_instructor[0].pfp} />
                    <div style={{
                        display: "flex",
                        flexDirection: "column"
                    }}>
                        <GenericText style={{
                            fontWeight: "bold",
                            fontSize: "1.7vh"
                            // @ts-ignore
                        }}>{this_instructor[0].name}</GenericText>
                        {/* @ts-ignore */}
                        <GenericText style={{
                            fontSize: "1.7vh",
                            color: "gray"
                            // @ts-ignore
                        }}>{this_instructor[0].email}</GenericText>
                    </div>
                </div>

                <GenericText as={"a"} style={{
                    color: "gray",
                    whiteSpace: "nowrap"
                }}><span style={{ marginRight: "1vw" }}>{(date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear()}</span> {time()}</GenericText>
            </div>

            <div>
                <GenericText style={{ marginBottom: 0, marginTop: "3vh" }} as={"p"}>{props.content}</GenericText>
            </div>
            {(window.localStorage.getItem('_role') === "instructor") ?
                <div style={{
                    marginTop: "2vh"
                }}>
                    <MemberOptionButton style={{
                        fontSize: "2vh"
                    }} onClick={() => {
                        props.removeFeedback(props.timestamp);
                    }} status={true}>
                        <FaTrashAlt />
                    </MemberOptionButton>
                </div>
                : <></>}
        </GivenFeedbackContainer>
    )
};

function usePrevious(value: any) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value; //assign the value of ref to the argument
    }, [value]); //this code will run when the value of 'value' changes
    return ref.current; //in the end, return the current ref value.
}

const Feedback = ({ ...props }: FeedbackProps) => {
    const [feedbackContent, setFeedbackContent] = useState(null);
    const [feedbackStatus, setFeedbackStatus] = useState(true);
    const [progressLevel, setProgressLevel] = useState(props.progress);
    const [socket, setSocket] = useState(null);

    // @ts-ignore
    const prevProgress = usePrevious(progressLevel);

    // @ts-ignore
    useEffect(() => {
        const new_socket = io(WS_API_URL, {
            extraHeaders: {
                // @ts-ignore
                authorization: window.localStorage.getItem("_token")
            }
        });

        // @ts-ignore
        setSocket(new_socket);
        return () => new_socket.close();
    }, []);

    useEffect(() => {
        if (prevProgress !== props.progress) {
            setProgressLevel(props.progress);
        }
    });

    const { data, isLoading, isSuccess } = useFindGoalsQuery({
        class_id: props.class_id,
        goal_id: props.goal_id,
        student_id: props.student_id
    });

    console.log(isSuccess);

    const [getGoal, { data: goalData, isSuccess: goalDataSuccess }] = useLazyFindGoalsQuery();

    const [updateGoal, { data: updateGoalData, isLoading: isUpdateGoalLoading, isSuccess: isUpdateGoalSuccess }] = useUpdateGoalMutation();

    const [createFeedback, { data: createFeedbackData, isLoading: isCreateFeedbackLoading, isSuccess: isCreateFeedbackSuccess }] = useCreateFeedbackMutation();

    const setProgress = async (value: string) => {
        const results = await updateGoal({
            student_id: props.student_id,
            class_id: props.class_id,
            goal_id: props.goal_id,
            progress: value
        });

        // @ts-ignore
        setProgressLevel(value);
    };

    const submitFeedback = async () => {
        if (feedbackContent !== null && feedbackContent !== "") {
            const results = await createFeedback({
                goal_id: props.goal_id,
                student_id: props.student_id,
                class_id: props.class_id,
                content: feedbackContent
            });

            // @ts-ignore
            if (results.data.data.error) {

            } else {
                window.location.reload();
            }
        } else {
            setFeedbackStatus(false);
        }
    };

    let feedback = [];
    let FeedbackComponents = [<></>];

    if (props.selected) {
        if (isSuccess) {
            if (data.data.length > 0) {
                feedback = [...data.data[0].feedback];
                feedback = feedback.reverse();
                FeedbackComponents = feedback.map((f: any, i: number) => {
                    if (!f.viewed && window.localStorage.getItem("_role") !== "instructor") {
                        if (socket) {
                            // @ts-ignore
                            socket.emit("view_feedback", {
                                goal_id: props.goal_id,
                                timestamp: f.timestamp
                            });
                        }
                    }

                    return <GivenFeedback
                        timestamp={f.timestamp}
                        content={f.content}
                        instructor_id={f.instructor_id}
                        instructors={props.instructors}
                        viewed={f.viewed}
                        // @ts-ignore
                        removeFeedback={props.removeFeedback}
                        key={i}
                    />
                });
            }
        }
    } else {
        return <div style={{
            width: "40vw",
            height: "59vh",
            backgroundColor: "whitesmoke",
            position: "absolute",
            right: 0,
            top: "32vh",
            borderRadius: "2vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <GenericText style={{
                color: "gray"
            }}>Goal not selected.</GenericText>
        </div>
    }

    return (
        <FeedbackContainer>
            <div style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between"
            }}>
                {
                    (isSuccess) ? <div style={{
                        display: "flex",
                        alignItems: "flex-start"
                    }}>
                        <GoalBadgeContainer style={{
                            backgroundColor: data.data[0].color
                        }}>
                            <img style={{ width: "1.5vw" }} src={GoalIcon} />
                        </GoalBadgeContainer>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1vh",
                            marginLeft: "1vw"
                        }}>
                            <GenericText style={{
                                fontWeight: "bold"
                            }}>{data.data[0].title}</GenericText>
                            <GenericText as={"p"} style={{
                                color: "gray",
                                marginTop: 0,
                                marginBottom: 0,
                                fontSize: "1.7vh",
                                maxWidth: "20vw"
                            }}>{data.data[0].description}</GenericText>
                        </div>
                    </div> : <div style={{
                        display: "flex",
                        alignItems: "flex-start"
                    }}>
                        <GoalBadgeContainer style={{
                            backgroundColor: "whitesmoke"
                        }}></GoalBadgeContainer>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1vh",
                            marginLeft: "1vw"
                        }}>
                            <GenericText style={{
                                fontWeight: "bold",
                                backgroundColor: "whitesmoke",
                                width: "20vw",
                                height: "2vh"
                            }}></GenericText>
                            <GenericText as={"p"} style={{
                                color: "gray",
                                marginTop: 0,
                                marginBottom: 0,
                                width: "20vw",
                                height: "5vh",
                                backgroundColor: "whitesmoke"
                            }}></GenericText>
                        </div>
                    </div>
                }

                {
                    (window.localStorage.getItem("_role") === "instructor") ? <MemberOptionButton onClick={() => {
                        // @ts-ignore
                        props.removeGoal();
                    }} status={true}>
                        <FaTrashAlt />
                    </MemberOptionButton> : <></>
                }
            </div>

            <div>

                {
                    (isSuccess) ?
                        (window.localStorage.getItem("_role") === "instructor") ? <div style={{
                            width: "100%",
                            boxSizing: "border-box",
                            paddingRight: "2vw",
                            paddingLeft: "2vw",
                            paddingTop: "2vw",
                            paddingBottom: "2vw",
                            display: "flex",
                            flexDirection: "column",
                            gap: "1vh",
                            backgroundColor: "#ECF8FF",
                            borderRadius: "2vh"
                        }}>
                            <div style={{
                                width: "100%",
                                display: "flex",
                                gap: "1vw",
                                marginBottom: "3vh"
                            }}>
                                <ProgressButton onClick={() => {
                                    // @ts-ignore
                                    setProgress("behind");
                                }} status={"behind"} valid={progressLevel === "behind"}><FaBook /> Behind</ProgressButton>
                                <ProgressButton onClick={() => {
                                    // @ts-ignore
                                    setProgress("on_track");
                                }} status={"on_track"} valid={progressLevel === "on_track"}><CgTrack />On Track</ProgressButton>
                                <ProgressButton
                                    status={"exceeding_expectations"}
                                    valid={progressLevel === "exceeding_expectations"}
                                    onClick={() => {
                                        // @ts-ignore
                                        setProgress("exceeding_expectations");
                                    }}><MdGrade /> Excelling</ProgressButton>
                            </div>
                            <GenericText style={{
                                fontWeight: "bold",
                                fontSize: "2.5vh",
                                color: "#344054"
                            }}>Create New Feedback</GenericText>
                            {/* @ts-ignore */}
                            <FeedbackInput style={{ height: "10vh" }} onChange={(e) => { setFeedbackContent(e.target.value); }} placeholder={"Enter some feedback to send to this student..."} />
                            <SubmitButton loading={isCreateFeedbackLoading} onClick={() => {
                                submitFeedback();
                            }} style={{
                                width: "100%"
                            }}>Submit Feedback</SubmitButton>
                        </div> : <div style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "2vh"
                        }}>
                            <ProgressButton status={"behind"} valid={data.data[0].progress === "behind"}><FaBook /> Behind</ProgressButton>
                            <ProgressButton status={"on_track"} valid={data.data[0].progress === "on_track"}><CgTrack />On Track</ProgressButton>
                            <ProgressButton
                                status={"exceeding_expectations"}
                                valid={data.data[0].progress === "exceeding_expectations"}><MdGrade /> Excelling</ProgressButton>
                        </div> : <></>
                }

                {
                    (isSuccess) ? <div>
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            gap: "5vh",
                            marginTop: "4vh"
                        }}>
                            {(FeedbackComponents.length > 0) ? FeedbackComponents :
                                <div style={{
                                    width: "100%",
                                    height: "40vh",
                                    backgroundColor: "whitesmoke",
                                    borderRadius: "2vh",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    <GenericText style={{ color: "gray" }}>No feedback yet.</GenericText>
                                </div>
                            }
                        </div>
                    </div> : (isSuccess) ?
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "5vh",
                            gap: "5vh"
                        }}>
                            {FeedbackComponents}
                        </div>
                        : <></>
                }
            </div>
        </FeedbackContainer>
    )
};

export default Feedback;