import React from 'react';

import { useFindAllClassesQuery } from '../../state/api';

import { useNavigate } from 'react-router-dom';
import { useFindChannelsQuery } from '../../state/api';
import { Placeholder, RowContainer, StudentRowCellContainer, StudentTableHeaderContainer, TableContainer, TableRowContainer } from '../primitives/containers';
import { GenericText } from '../primitives/typography';
import { BiHomeAlt } from 'react-icons/bi';
import { S3_URL } from '../../constants';

import { Oval } from 'react-loader-spinner';

interface TableRowProps {
    name: String
    pfp: String
    channel_id: String
    last_message: Array<Object> | null
    classes: Array<String>
    key: number
};

interface ClassButtonProps {
    class_id: String | null
};

const ClassButton = ({ ...props }: ClassButtonProps) => {
    const navigate = useNavigate();

    // @ts-ignore
    const { data, isSuccess } = useFindAllClassesQuery();

    if (isSuccess) {
        const curr = data.data.filter((c: any) => {
            return c.class_id === props.class_id
        })[0];

        return <button style={{
            backgroundColor: curr.color,
            width: "3vw",
            height: "3vw",
            borderRadius: "1vh",
            border: "none",
            fontSize: "2vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "rgb(0, 0, 0, 0.5)",
            cursor: "pointer"
        }} onClick={() => {
            navigate("/class/" + props.class_id);
        }}>
            <BiHomeAlt />
        </button>
    } else {
        return <></>
    }
}

const TableRow = ({ ...props }: TableRowProps) => {
    const navigate = useNavigate();

    let date = new Date(0);

    const time = () => {
        let hours;
        let pm = false;
        if (date.getHours() > 12) {
            hours = date.getHours() - 12;
            pm = true;
        } else {
            hours = date.getHours();
        }

        const dayOfWeek = (date.getDay() === 0) ? "Sunday" : "";

        return (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear() + " " + hours + ":" + ((date.getMinutes() < 10) ? "0" + date.getMinutes() : date.getMinutes()) + ((pm) ? "pm" : "am");
    }

    if (props.last_message) {
        // @ts-ignore
        date.setUTCSeconds(props.last_message.timestamp / 1000);
    }

    return (
        <RowContainer>
            <StudentRowCellContainer style={{
                paddingLeft: "1vw",
                paddingTop: "2vh",
                paddingBottom: "2vh",
                display: "flex",
                alignItems: "center",
                gap: "1vw",
                cursor: "pointer"
            }} onClick={() => {
                navigate("/messages/channel/" + props.channel_id);
            }}>
                <img style={{
                    borderRadius: "100vh",
                    width: "3vw",
                    height: "3vw"
                }} src={S3_URL + props.pfp} />
                <GenericText>{props.name}</GenericText>
            </StudentRowCellContainer>
            <StudentRowCellContainer>
                {props.classes.map((c_id: String, i: number) => {
                    return <ClassButton key={i} class_id={c_id} />
                })}
            </StudentRowCellContainer>
            <StudentRowCellContainer>
                {(props.last_message) ? <GenericText>{time()}</GenericText> : <Placeholder />}
            </StudentRowCellContainer>
        </RowContainer>
    )
};

const MessageTable = () => {
    // @ts-ignore
    const { data, isSuccess, isLoading } = useFindChannelsQuery();

    let channels = [];
    let ChannelComponents = [];

    if (isSuccess) {
        channels = data.data.filter((c: any) => {
            return c.last_message !== null;
        });

        channels = channels.map((c: any) => {
            let curr = { ...c };
            if (c.last_message) {
                curr.last_message = JSON.parse(c.last_message);
            }

            return curr;
        });

        ChannelComponents = channels.map((c: any, i: number) => {
            const recipient = c.members.filter((m: any) => { return m.user_id !== window.localStorage.getItem("_user_id") })[0];

            return <TableRow
                name={recipient.name}
                pfp={recipient.pfp}
                channel_id={c.channel_id}
                last_message={c.last_message}
                classes={c.classes}
                key={i}
            />
        });
    }

    return (
        <TableContainer style={{
            border: "1px solid lightgray"
        }}>
            <StudentTableHeaderContainer>
                <StudentRowCellContainer header={true}>{(window.localStorage.getItem("_role") === "instructor") ? "Student" : "Instructor"}</StudentRowCellContainer>
                <StudentRowCellContainer header={true}>Class</StudentRowCellContainer>
                <StudentRowCellContainer header={true}>Last Message</StudentRowCellContainer>
            </StudentTableHeaderContainer>
            {
                (isSuccess) ? ((channels.length === 0) ? <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "57vh" }}>
                    <GenericText style={{ color: "gray" }}>No messages.</GenericText>
                </div> : <TableRowContainer style={{
                    alignItems: "center"
                }}>
                    {ChannelComponents}
                </TableRowContainer>) : (isLoading) ? <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "57vh" }}>
                    <Oval
                        height={40}
                        width={40}
                        color="#6EA8C8"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="white"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </div> : <></>
            }
        </TableContainer>
    )
};

export default MessageTable;