import React, { useState } from 'react';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import { FaChevronLeft, FaChevronRight, FaDotCircle } from 'react-icons/fa';
import { useSignInMutation, useUpdateUserMutation } from '../../../state/api';
import { SettingCardContainer, SettingContainer } from '../../primitives/containers';
import { GenericInput, SettingButton, SubmitButton, SettingSubmitButton } from '../../primitives/interactions';
import { GenericText } from '../../primitives/typography';

const PasswordSetting = () => {
    const [index, setIndex] = useState(0);
    const [password1, setPassword1] = useState(null);
    const [password2, setPassword2] = useState(null);
    const [password3, setPassword3] = useState(null);
    const [status, setStatus] = useState(true);
    const [password1Status, setPassword1Status] = useState(true);
    const [password2Status, setPassword2Status] = useState(true);
    const [password3Status, setPassword3Status] = useState(true);

    const [signIn] = useSignInMutation();
    const [updateUser] = useUpdateUserMutation();

    const submitPasswordChange = async () => {
        const signin_results = await signIn({
            // @ts-ignore
            email: window.localStorage.getItem("_email"),
            // @ts-ignore
            password: password1
        });

        console.log(signin_results);

        // @ts-ignore
        if (signin_results.data.authorization) {
            if (password1 !== password2) {
                if (password2 === password3) {
                    const results = await updateUser({
                        password: password3
                    });

                    // @ts-ignore
                    if (results.data.data) {
                        window.location.reload();
                    } else {
                        setPassword1Status(false);
                        setPassword2Status(false);
                        setPassword3Status(false);
                    }
                }
            } else {
                setPassword2Status(false);
                setPassword3Status(false);
            }
        } else {
            setPassword1Status(false);
        }
    };

    return <SettingContainer>
        {/* @ts-ignore */}
        <GenericInput defaultValue={password1} type={"password"} style={{ fontSize: "2vh" }} placeholder={"Enter your current password..."} status={password1Status} onChange={(e: any) => {
            setStatus(e.currentTarget.value !== null && e.currentTarget.value !== "" && password2 === password3);
            // @ts-ignore
            setPassword1(e.currentTarget.value);
        }} />
        {/* @ts-ignore */}
        <GenericInput defaultValue={password2} type={"password"} style={{ fontSize: "2vh" }} placeholder={"Enter your new password..."} status={password2Status} onChange={(e: any) => {
            setStatus(e.currentTarget.value !== null && e.currentTarget.value !== "" && e.currentTarget.value === password3);
            // @ts-ignore
            setPassword2(e.currentTarget.value);
        }} />
        {/* @ts-ignore */}
        <GenericInput defaultValue={password3} type={"password"} style={{ fontSize: "2vh" }} placeholder={"Re-enter your new password..."} status={password3Status} onChange={(e: any) => {
            setStatus(e.currentTarget.value !== null && e.currentTarget.value !== "" && e.currentTarget.value === password2);
            // @ts-ignore
            setPassword3(e.currentTarget.value);
        }} />
        <SettingSubmitButton status={status} onClick={() => {
            submitPasswordChange();
        }} style={{ marginTop: 0 }}>Submit Change</SettingSubmitButton>
    </SettingContainer>
}

const EmailSetting = () => {
    const og_email = window.localStorage.getItem("_email");
    const [email, setEmail] = useState(og_email);
    const [status, setStatus] = useState(false);
    const [inputStatus, setInputStatus] = useState(true);

    const [updateUser] = useUpdateUserMutation();

    const submitEmailChange = async () => {
        if (email !== og_email) {
            const results = await updateUser({
                email
            });

            // @ts-ignore
            if (results.data.error) {
                setInputStatus(false);
            } else {
                // @ts-ignore
                window.localStorage.setItem("_email", email);
                window.location.reload();
            }
        }
    }

    return (
        <SettingContainer>
            <GenericInput status={inputStatus} style={{ fontSize: "2vh" }} onKeyUp={(e: any) => {
                setStatus(!(e.currentTarget.value === og_email))
                setEmail(e.currentTarget.value)
                // @ts-ignore
            }} defaultValue={email} />
            <SettingSubmitButton onClick={() => {
                submitEmailChange();
            }} status={status} style={{
                marginTop: 0
            }}>Submit Change</SettingSubmitButton>
        </SettingContainer>
    )
}

const NameSetting = () => {
    const full_name = window.localStorage.getItem("_name");
    const first_name = full_name?.split(" ")[0];
    const last_name = full_name?.split(" ")[1];

    const [firstName, setFirstName] = useState(first_name);
    const [lastName, setLastName] = useState(last_name);
    const [status, setStatus] = useState(false);

    const [updateUser] = useUpdateUserMutation();

    const submitNameChange = async () => {
        if (firstName !== first_name || last_name !== lastName) {
            const results = await updateUser({
                name: firstName + " " + lastName
            });

            window.localStorage.setItem("_name", firstName + " " + lastName);
            window.location.reload();
        }
    }

    return (
        <SettingContainer>
            <GenericInput status={true} style={{ fontSize: "2vh" }} onKeyUp={(e: any) => {
                setStatus(!(e.currentTarget.value === first_name))
                setFirstName(e.currentTarget.value)
            }} defaultValue={firstName} />
            <GenericInput status={true} style={{ fontSize: "2vh" }} onKeyUp={(e: any) => {
                setLastName(e.currentTarget.value)
            }} defaultValue={lastName} />
            <SettingSubmitButton onClick={() => {
                submitNameChange();
            }} status={status} style={{
                marginTop: 0
            }}>Submit Change</SettingSubmitButton>
        </SettingContainer>
    )
}

interface SettingCardProps {
    name?: String

};

const Setting = ({ ...props }: SettingCardProps) => {
    const [nameOpen, setNameOpen] = useState(false);
    const [emailOpen, setEmailOpen] = useState(false);
    const [passwordOpen, setPasswordOpen] = useState(false);

    return <SettingCardContainer>
        <SettingButton style={{
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0
        }} status={nameOpen} onClick={() => {
            setNameOpen(!nameOpen);
        }}>
            <GenericText style={{
                fontWeight: "bold"
            }}>Name</GenericText>

            <div style={{
                display: "flex",
                alignItems: "center",
                gap: "1vw"
            }}>
                <GenericText style={{
                    color: "gray"
                }}>{window.localStorage.getItem('_name')}</GenericText>
                <GenericText style={{
                    fontSize: "1.5vh"
                }}>{(!nameOpen) ? <FaChevronRight /> : <FaChevronLeft />}</GenericText>
            </div>
        </SettingButton>

        {(nameOpen) ? <NameSetting /> : <></>}

        <SettingButton style={{
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0
        }} status={emailOpen} onClick={() => {
            setEmailOpen(!emailOpen);
        }}>
            <GenericText style={{
                fontWeight: "bold"
            }}>Email</GenericText>

            <div style={{
                display: "flex",
                alignItems: "center",
                gap: "1vw"
            }}>
                <GenericText style={{
                    color: "gray"
                }}>{window.localStorage.getItem('_email')}</GenericText>
                <GenericText style={{
                    fontSize: "1.5vh"
                }}>{(!emailOpen) ? <FaChevronRight /> : <FaChevronLeft />}</GenericText>
            </div>
        </SettingButton>

        {(emailOpen) ? <EmailSetting /> : <></>}

        <SettingButton style={{
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0
        }} status={passwordOpen} onClick={() => {
            setPasswordOpen(!passwordOpen);
        }}>
            <GenericText style={{
                fontWeight: "bold"
            }}>Password</GenericText>

            <div style={{
                display: "flex",
                alignItems: "center",
                gap: "1vw"
            }}>
                <GenericText style={{
                    color: "gray",
                    fontSize: "1vh",
                    display: "flex",
                    gap: "0.2vw"
                }}><FaDotCircle /><FaDotCircle /><FaDotCircle /><FaDotCircle /><FaDotCircle /><FaDotCircle /><FaDotCircle /><FaDotCircle /><FaDotCircle /><FaDotCircle /><FaDotCircle /><FaDotCircle /><FaDotCircle /></GenericText>
                <GenericText style={{
                    fontSize: "1.5vh"
                }}>{(!passwordOpen) ? <FaChevronRight /> : <FaChevronLeft />}</GenericText>
            </div>
        </SettingButton>

        {(passwordOpen) ? <PasswordSetting /> : <></>}
    </SettingCardContainer>
};

export default Setting;