import React, { useState } from 'react';

import { FaTimes } from 'react-icons/fa';

import { ModalHeaderTitle } from '../../../primitives/typography';
import { ExitButton, GenericInput, SubmitButton } from '../../../primitives/interactions';
import { JoinClassContainer, ModalHeaderContainer } from '../../../primitives/containers';
import { useJoinClassMutation } from '../../../../state/api';

interface JoinClassProps {
    closeModal: Function
    joinClass: Function
};

const JoinClass = ({ ...props }: JoinClassProps) => {
    const [classId, setClassId] = useState(null);
    const [joinCode, setJoinCode] = useState(null);
    const [classIdStatus, setClassIdStatus] = useState(true);
    const [joinCodeStatus, setJoinCodeStatus] = useState(true);

    const [joinClass, { data, isLoading, isSuccess }] = useJoinClassMutation();

    const SubmitRequest = async () => {
        const results = await joinClass({
            // @ts-ignore
            join_code: joinCode
        });

        // @ts-ignore
        if (results.data.data.error) {
            // @ts-ignore
            if (results.data.data.error === "invalid_join_code") {
                setJoinCodeStatus(false);
            }
        } else {
            window.location.reload();
            // @ts-ignore
            props.closeModal();
        }
    };

    return (
        <JoinClassContainer>
            <ModalHeaderContainer>
                <ModalHeaderTitle style={{ fontSize: "2.5vh" }}>Join Class</ModalHeaderTitle>

                <ExitButton onClick={() => {
                    props.closeModal();
                }}>
                    <FaTimes />
                </ExitButton>
            </ModalHeaderContainer>

            <div style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "2vh",
                marginTop: "5vh"
            }}>
                <GenericInput onChange={(e: any) => {
                    // @ts-ignore
                    setJoinCode(e.target.value);
                }} style={{ fontSize: "2vh" }} status={joinCodeStatus} placeholder={"Enter a Join Code..."} />
            </div>
            <SubmitButton loading={isLoading} onClick={() => {
                SubmitRequest();
            }} style={{
                width: "100%"
            }}>Join Class</SubmitButton>
        </JoinClassContainer>
    )
};

export default JoinClass;