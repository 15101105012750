import React from 'react';

import { AiFillDelete } from 'react-icons/ai';
import { BiMessageAltDetail } from 'react-icons/bi';
import { FaUserAlt } from 'react-icons/fa';

import { FaTrashAlt, FaBook } from 'react-icons/fa';
import { IoMdMap } from 'react-icons/io';
import { MdGrade } from 'react-icons/md';
import { CgTrack } from 'react-icons/cg';

import { useFindAllClassesQuery, useFindClassMembersQuery, useFindGoalsQuery } from '../../state/api';
import { Placeholder, RowContainer, StudentRowCellContainer, StudentTableHeaderContainer, TableContainer, TableRowContainer } from '../primitives/containers';

// @ts-ignore
import DefaultPFP from '../../assets/default_pfp.jpg';
import { ActivityStudentText, GenericText } from '../primitives/typography';

import { IoMdSchool } from 'react-icons/io';
import { MemberOptionButton } from '../primitives/interactions';
import { useNavigate, useParams } from 'react-router-dom';
import { S3_URL } from '../../constants';

import { Oval } from 'react-loader-spinner';

interface StudentTableProps {
    class_id: String
    removeMember: Function
};

interface RowProps {
    user_id: String
    name: String
    email: String
    role: String
    progress: String
    removeMember: Function
    pfp: String
    channels?: Array<any>
};

const TableRow = ({ ...props }: RowProps) => {
    const navigate = useNavigate();

    const { class_id } = useParams();

    const { data, isLoading, isSuccess } = useFindGoalsQuery({
        class_id: class_id,
        student_id: props.user_id
    });

    let goals = [];
    let progress_val = "";
    let date = new Date(0);
    let time = () => { return "" };
    let ProgressIcons = [];

    if (isSuccess) {
        goals = data.data;
        progress_val = "";

        time = () => {
            let hours;
            let pm = false;
            if (date.getHours() > 12) {
                hours = date.getHours() - 12;
                pm = true;
            } else {
                hours = date.getHours();
            }

            const dayOfWeek = (date.getDay() === 0) ? "Sunday" : "";

            return (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear() + " " + hours + ":" + ((date.getMinutes() < 10) ? "0" + date.getMinutes() : date.getMinutes()) + ((pm) ? "pm" : "am");
        }

        ProgressIcons = [];

        if (goals !== undefined) {
            let progress = goals.map((g: any) => {
                return g.progress;
            });

            const ee_count = progress.reduce(function (counter: any, item: any) { return counter + (item === "exceeding_expectations" ? 1 : 0); }, 0);
            const behind_count = progress.reduce(function (counter: any, item: any) { return counter + (item === "behind" ? 1 : 0); }, 0);
            const on_track_count = progress.reduce(function (counter: any, item: any) { return counter + (item === "on_track" ? 1 : 0); }, 0);

            for (let i = 0; i < progress.length; i++) {
                if (i < 5) {
                    if (progress[i] === "exceeding_expectations") {
                        ProgressIcons.push(<MdGrade key={i} color={"#0ad48b"} />);
                    } else if (progress[i] === "behind") {
                        ProgressIcons.push(<FaBook key={i} size={15} color={"#F9C11D"} />)
                    } else {
                        ProgressIcons.push(<CgTrack key={i} color={"#6EA8C8"} />);
                    }
                }
            }

            if (ee_count + behind_count + on_track_count > 5) {
                ProgressIcons.push(<div key={ProgressIcons.length} style={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "whitesmoke",
                    fontSize: "1vh",
                    width: "1.5vw",
                    height: "1.5vw",
                    justifyContent: "center",
                    borderRadius: "100vh"
                }}>
                    {"+" + ((ee_count + behind_count + on_track_count) - 5)}
                </div>);
            }

            let feedback_times = goals.map((g: any) => {
                if (g.feedback.length > 0) {
                    return Math.max.apply(Math, g.feedback.map(function (o: any) { return o.timestamp; }))
                } else {
                    return 0;
                }
            });

            if (feedback_times.length > 0 && feedback_times[0] > 0) {
                const recent_feedback_timestamp = Math.max.apply(Math, feedback_times)
                date.setUTCSeconds(recent_feedback_timestamp / 1000);
            } else {
                // @ts-ignore
                date = null;
            }
        }
    }

    if (props.role === "instructor") {
        return (
            <RowContainer>
                <StudentRowCellContainer style={{
                    paddingLeft: "1vw",
                    paddingTop: "2vh",
                    paddingBottom: "2vh",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    userSelect: "none"
                }}>
                    <img style={{
                        borderRadius: "100vh",
                        width: "3vw",
                        height: "3vw"
                    }} src={S3_URL + props.pfp} />
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "1vw"
                    }}>
                        <ActivityStudentText style={{ display: "flex", alignItems: "center", gap: "0.5vw", fontWeight: "bold", color: "black" }}>
                            {(props.role === "instructor") ? <IoMdSchool color={"#6EA8C8"} /> : <></>}
                            {props.name}
                        </ActivityStudentText>
                        <ActivityStudentText>{props.email}</ActivityStudentText>
                    </div>
                </StudentRowCellContainer>
                <StudentRowCellContainer>
                    <Placeholder />
                </StudentRowCellContainer>
                <StudentRowCellContainer>
                    <Placeholder />
                </StudentRowCellContainer>
            </RowContainer>
        )
    } else {
        return (
            <RowContainer>
                <StudentRowCellContainer onClick={() => {
                    navigate(window.location.pathname + "/student/" + props.user_id);
                }} style={{
                    paddingLeft: "1vw",
                    paddingTop: "2vh",
                    paddingBottom: "2vh",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    userSelect: "none"
                }}>
                    <img style={{
                        borderRadius: "100vh",
                        width: "3vw",
                        height: "3vw"
                    }} src={S3_URL + props.pfp} />
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "1vw"
                    }}>
                        <ActivityStudentText style={{ display: "flex", alignItems: "center", gap: "0.5vw", fontWeight: "bold", color: "black" }}>
                            <FaUserAlt color={"#6EA8C8"} size={15} />
                            {props.name}
                        </ActivityStudentText>
                        <ActivityStudentText>{props.email}</ActivityStudentText>
                    </div>
                </StudentRowCellContainer>
                <StudentRowCellContainer>
                    {(date) ? <GenericText>{time()}</GenericText> : <Placeholder />}
                </StudentRowCellContainer>
                <StudentRowCellContainer style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                }}>
                    <div style={{
                        width: "10vw"
                    }}>
                        {(ProgressIcons.length > 0) ?
                            <GenericText style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "0.5vw",
                                color: (progress_val === "on_track") ? "#6EA8C8" : (progress_val === "excelling") ? "#0ad48b" : ""
                            }}>
                                {ProgressIcons}
                            </GenericText>
                            : <Placeholder />}
                    </div>

                    <div style={{
                        display: "flex",
                        gap: "1vw",
                        marginRight: "2vw"
                    }}>
                        <MemberOptionButton onClick={() => {
                            props.removeMember(props.user_id);
                        }} status={true}><AiFillDelete /></MemberOptionButton>
                        <MemberOptionButton onClick={() => {
                            // @ts-ignore
                            navigate("/messages/channel/" + props.channels[0].channel_id)
                        }}><BiMessageAltDetail /></MemberOptionButton>
                    </div>
                </StudentRowCellContainer>
            </RowContainer>
        )
    }
};

const StudentTable = ({ ...props }: StudentTableProps) => {
    const { data, isLoading, isSuccess } = useFindClassMembersQuery({ class_id: props.class_id });

    let MemberComponents = []

    if (isSuccess) {
        MemberComponents = data.data.map((member: any, i: number) => {
            return <TableRow
                name={member.name}
                email={member.email}
                role={member.role}
                user_id={member.user_id}
                progress={member.progress}
                removeMember={props.removeMember}
                pfp={member.pfp}
                channels={member.channels}
                key={i}
            />
        });
    }
    return (
        <TableContainer style={{
            border: "1px solid lightgray"
        }}>
            <StudentTableHeaderContainer>
                <StudentRowCellContainer header={true}>Class Member</StudentRowCellContainer>
                <StudentRowCellContainer header={true}>Recent Feedback</StudentRowCellContainer>
                <StudentRowCellContainer header={true}>Progress</StudentRowCellContainer>
            </StudentTableHeaderContainer>
            {
                (isSuccess) ? ((MemberComponents.length > 0) ? <TableRowContainer style={{
                    alignItems: "center"
                }}>
                    {MemberComponents}
                </TableRowContainer>
                    : <></>) : (isLoading) ? <TableRowContainer style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: 'center',
                        height: "55vh"
                    }}>
                        <Oval
                            height={40}
                            width={40}
                            color="#6EA8C8"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor="white"
                            strokeWidth={2}
                            strokeWidthSecondary={2}
                        />
                    </TableRowContainer> : <></>
            }
        </TableContainer>
    )
};

export default StudentTable;