import React, { useState } from 'react';
import { SectionContainer, SignUpFormContainer } from '../../../../components/primitives/containers';
import { HeaderIcon } from '../../../../components/primitives/images';

// @ts-ignore
import Logo from '../../../../assets/logo.svg';
import { FeatureDescription, FeatureTitle } from '../../../../components/primitives/typography';
import { AuthInput, SubmitButton } from '../../../../components/primitives/interactions';

interface NameProps {
    incrementIndex: Function
    decrementIndex: Function
    setFinalName: Function
}

const Name = ({ ...props }: NameProps) => {
    const {
        incrementIndex,
        decrementIndex,
        setFinalName
    } = props;

    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [firstNameStatus, setFirstNameStatus] = useState(true);
    const [lastNameStatus, setLastNameStatus] = useState(true);

    const SubmitName = () => {
        if(firstName !== null && firstName !== "") {
            if(lastName !== null && lastName !== "") {
                setFirstNameStatus(true);
                setLastNameStatus(true);
                setFinalName(firstName! + " " + lastName!);
                incrementIndex();
            } else {
                setLastNameStatus(false);
            }
        } else {
            setLastNameStatus(false);
        }
    };

    return (
        <SectionContainer style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <SignUpFormContainer>
                <HeaderIcon style={{
                    width: "3vw"
                }} src={Logo} />
                <FeatureTitle style={{
                    fontSize: "5vh"
                }}>Choose your name</FeatureTitle>
                <FeatureDescription style={{
                    fontSize: "2vh"
                }}>
                    Make sure its your real name.
                </FeatureDescription>
                {/* @ts-ignore */}
                <AuthInput status={firstNameStatus} onChange={(e) => { setFirstName(e.target.value) }} style={{ marginTop: "4vh" }} placeholder={"Enter your first name..."} />
                {/* @ts-ignore */}
                <AuthInput status={lastNameStatus} onChange={(e) => { setLastName(e.target.value) }} style={{ marginTop: "2vh" }} placeholder={"Enter your last name..."} />
                <SubmitButton onClick={SubmitName}>Continue</SubmitButton>
            </SignUpFormContainer>
        </SectionContainer>
    );
};

export default Name;