import React from 'react';

import { FaTimes, FaUserAlt } from 'react-icons/fa';
import { IoMdSchool } from 'react-icons/io';

import { ExitButton } from '../../../primitives/interactions';
import { AddMemberContainer, CodeContainer, ModalHeaderContainer } from '../../../primitives/containers';
import { JoinCode, ModalHeaderTitle } from '../../../primitives/typography';

interface AddMemberProps {
    closeModal: Function
    joinCode: String
    classId: String
}

const AddMember = ({ ...props }: AddMemberProps) => {
    return (
        <AddMemberContainer style={{
            width: "30vw"
        }}>
            <ModalHeaderContainer>
                <ModalHeaderTitle style={{ fontSize: "2.5vh" }}>Add Class Member</ModalHeaderTitle>
                <ExitButton onClick={() => {
                    props.closeModal();
                }}>
                    <FaTimes />
                </ExitButton>
            </ModalHeaderContainer>

            <div style={{
                width: "100%",
                display: "flex",
                marginTop: "5vh",
                flexDirection: "column",
                gap: "2vh"
            }}>
                <CodeContainer>
                    <span style={{
                        fontSize: "2vh",
                        display: "flex",
                        alignItems: "center",
                        gap: "0.5vw"
                    }}>
                        <IoMdSchool />
                        Join Code
                    </span>
                    <JoinCode>{props.joinCode}</JoinCode>
                </CodeContainer>
            </div>
        </AddMemberContainer>
    )
};

export default AddMember;