import React from 'react';
import { useNavigate } from 'react-router-dom';

import { HeaderContainer, HeaderIconContainer, HeaderNavLinksContainer } from '../primitives/containers';
import { HeaderIcon } from '../primitives/images';

// @ts-ignore
import Logo from '../../assets/logo.svg';
import { HeaderLogoText } from '../primitives/typography';
import { SignInButton, SignUpButton } from '../primitives/interactions';

const LandingNavigation = () => {
    const navigate = useNavigate();

    return (
        <HeaderContainer>
            <HeaderIconContainer>
                <HeaderIcon src={Logo} />
                <HeaderLogoText>upstream</HeaderLogoText>
            </HeaderIconContainer>

            <HeaderNavLinksContainer>
                <SignInButton onClick={() => {
                    navigate("/signin");
                }}>
                    Log In
                </SignInButton>

                <SignUpButton onClick={() => {
                    navigate("/registration");
                }}>
                    Sign Up
                </SignUpButton>
            </HeaderNavLinksContainer>
        </HeaderContainer>
    )
};

export default LandingNavigation;