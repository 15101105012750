import React from 'react';

import LandingNav from '../../components/nav/landing';

import { HeaderLogoText } from '../../components/primitives/typography';
import { HeaderIcon } from '../../components/primitives/images';
// @ts-ignore
import { MainContainer, SectionContainer, HeaderIconContainer, LandingHeroTextContainer, SectionInteriorContainer } from '../../components/primitives/containers.tsx';
import { FeatureIcon, LandingHero } from '../../components/primitives/images';
import { SignInButton, SignUpButton } from '../../components/primitives/interactions';
import { FeatureContainer, FeatureDescription, FeaturesDescription, FeaturesGroup, FeaturesHeader, FeaturesText, FeatureTitle, LandingHeroDescription, LandingHeroHeader } from '../../components/primitives/typography';

// @ts-ignore
import HomeScreenshot from '../../assets/home_screenshot.png';
import { FeaturesHeaderContainer, PricingCardContainer } from '../../components/primitives/containers';

// @ts-ignore
import Logo from '../../assets/logo.svg';

// @ts-ignore
import UserIcon from '../../assets/img_user.svg';
// @ts-ignore
import AirplaneIcon from '../../assets/img_airplane.svg';
// @ts-ignore
import LocationIcon from '../../assets/img_location.svg';
// @ts-ignore
import FeaturedIcon from '../../assets/img_featuredicon.svg';
import PricingCard from '../../components/composite/cards/pricing';
import { useNavigate } from 'react-router-dom';

const Home = () => {
    const navigate = useNavigate();

    return (
        <MainContainer style={{
            overflowX: "hidden"
        }}>
            <LandingNav />

            <SectionContainer style={{
                paddingLeft: "20vw",
                alignItems: "center",
                gap: "10vw",
                backgroundImage: "linear-gradient(to bottom right, white 50%, #6fa8cb)"
            }}>
                <LandingHeroTextContainer>
                    <LandingHeroHeader>
                        Student Feedback Reimagined
                    </LandingHeroHeader>
                    <LandingHeroDescription>
                        Realtime feedback on student goals. Join now.
                    </LandingHeroDescription>
                    <div style={{
                        display: "flex",
                        gap: "1vw"
                    }}>
                        <SignInButton onClick={() => {
                            navigate("/signin");
                        }}>
                            Log In
                        </SignInButton>
                        <SignUpButton onClick={() => {
                            navigate("/registration");
                        }}>
                            Sign Up
                        </SignUpButton>
                    </div>
                </LandingHeroTextContainer>

                <LandingHero src={HomeScreenshot} />
            </SectionContainer>

            <SectionContainer style={{
                justifyContent: "center",
                marginTop: "20vh"
            }}>
                <SectionInteriorContainer>
                    <FeaturesHeaderContainer>
                        <FeaturesText>Features</FeaturesText>
                        <FeaturesHeader>
                            A new supercharged feedback loop.
                        </FeaturesHeader>
                        <FeaturesDescription>
                            The old paradigm of once-a-semester parent teacher conferences is over - Upstream is a new system that align students and teachers around a common set of goals.
                        </FeaturesDescription>
                    </FeaturesHeaderContainer>

                    <FeaturesGroup>
                        <FeatureContainer>
                            <FeatureIcon src={UserIcon} />
                            <FeatureTitle>Easy Feedback</FeatureTitle>
                            <FeatureDescription>Never leave your students in the dark. With clear goals and updates, the goal progress is clear for both parties.</FeatureDescription>
                        </FeatureContainer>

                        <FeatureContainer>
                            <FeatureIcon src={AirplaneIcon} />
                            <FeatureTitle>Rapid Interface</FeatureTitle>
                            <FeatureDescription>Easy to user interface enables teachers to track student goals in one place.</FeatureDescription>
                        </FeatureContainer>
                    </FeaturesGroup>
                    <FeaturesGroup>
                        <FeatureContainer>
                            <FeatureIcon src={LocationIcon} />
                            <FeatureTitle>Communicate Clearly</FeatureTitle>
                            <FeatureDescription>Maintain a track record of messages and progress that can be shared with others who are granted access.</FeatureDescription>
                        </FeatureContainer>

                        <FeatureContainer>
                            <FeatureIcon src={FeaturedIcon} />
                            <FeatureTitle>Better Experience</FeatureTitle>
                            <FeatureDescription>Formative feedback enables more cycles and quicker progress toward goal attainment.</FeatureDescription>
                        </FeatureContainer>
                    </FeaturesGroup>
                </SectionInteriorContainer>
            </SectionContainer>

            <SectionContainer style={{
                justifyContent: "center",
                marginTop: "30vh",
                backgroundColor: "#F2F4F7",
                height: "min-content",
                paddingBottom: "20vh"
            }}>
                <SectionInteriorContainer style={{
                    paddingTop: "20vh"
                }}>
                    <FeaturesHeaderContainer>
                        <FeaturesText>Pricing</FeaturesText>
                        <FeaturesHeader>
                            All for free.
                        </FeaturesHeader>
                        <FeaturesDescription>
                            Subject to change, but for now, enjoy!
                        </FeaturesDescription>
                    </FeaturesHeaderContainer>

                    <PricingCard
                        price={0}
                        description={"Built for classrooms and students alike"}
                        features={[
                            "Access to everything",
                            "Unlimited students",
                            "Free updates"
                        ]}
                        tier={"Free"}
                    />
                </SectionInteriorContainer>
            </SectionContainer>
            <SectionContainer style={{
                background: "linear-gradient(to bottom right, #6ea8cb, #2d5f7a)",
                height: "min-content",
                justifyContent: "center",
            }}>
                <SectionInteriorContainer style={{
                    paddingTop: "10vh",
                    paddingBottom: "10vh"
                }}>
                    <FeaturesHeaderContainer>
                        <FeaturesHeader style={{
                            color: "white",
                            marginBottom: 0
                        }}>
                            Level up your classroom.
                        </FeaturesHeader>
                        <FeaturesDescription style={{
                            color: "rgb(255,255,255, 0.5)"
                        }}>
                            Create an account in minutes.
                        </FeaturesDescription>
                        <SignInButton style={{
                            color: "#6ea8cb"
                        }} onClick={() => {
                            navigate("/registration");
                        }}>
                            Get Started
                        </SignInButton>
                    </FeaturesHeaderContainer>
                </SectionInteriorContainer>
            </SectionContainer>

            <SectionContainer style={{
                height: "min-content",
                padding: "4vh",
                paddingLeft: "3vw"
            }}>
                <HeaderIconContainer>
                    <HeaderIcon src={Logo} />
                    <HeaderLogoText>upstream</HeaderLogoText>
                </HeaderIconContainer>
            </SectionContainer>
        </MainContainer>
    )
};

export default Home;
